//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { autocomplete } from '~/mixins/autocomplete'

export default {

    mixins: [autocomplete],

    data() {
        return {
            loadApi: false,
        }
    },

}

