export const state = () => ({

})

export const mutations = {

}

export const actions = {

    news ({ commit, state }) {
        return new Promise((resolve, reject) => {
            this.$axios.get(this.$ziggy.route('api.provider.news'))
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    newsData ({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(this.$ziggy.route('api.provider.news.data'),
            {
                        params: {
                            id: id,
                    }
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    updateNews ({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.post(this.$ziggy.route('api.provider.news.update'),
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    addNews ({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            this.$axios.post(this.$ziggy.route('api.provider.news.add'),
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    deleteNews ({ commit, state }, id) {
        return new Promise((resolve, reject) => {
            this.$axios.get(this.$ziggy.route('api.provider.news.delete'),
                {
                    params: {
                        id: id,
                    }
                })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

}
