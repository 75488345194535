import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _95330718 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3a5eb893 = () => interopDefault(import('../pages/academy.vue' /* webpackChunkName: "pages/academy" */))
const _6a7544d6 = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _e59f1c88 = () => interopDefault(import('../pages/academy/courses.vue' /* webpackChunkName: "pages/academy/courses" */))
const _e29f4c1c = () => interopDefault(import('../pages/academy/course/detail/_id/_slug/index.vue' /* webpackChunkName: "pages/academy/course/detail/_id/_slug/index" */))
const _cc1be4a0 = () => interopDefault(import('../pages/academy/course/watch/_id/_slug/index.vue' /* webpackChunkName: "pages/academy/course/watch/_id/_slug/index" */))
const _015cceee = () => interopDefault(import('../pages/buyerprograms.vue' /* webpackChunkName: "pages/buyerprograms" */))
const _0f93f872 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _007407d4 = () => interopDefault(import('../pages/directory.vue' /* webpackChunkName: "pages/directory" */))
const _91cdf866 = () => interopDefault(import('../pages/event-detail.vue' /* webpackChunkName: "pages/event-detail" */))
const _74124186 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _31bc4400 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _9ce9d52c = () => interopDefault(import('../pages/faq/search.vue' /* webpackChunkName: "pages/faq/search" */))
const _f656756a = () => interopDefault(import('../pages/media.vue' /* webpackChunkName: "pages/media" */))
const _54ace78e = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _52bf3e8d = () => interopDefault(import('../pages/media/events.vue' /* webpackChunkName: "pages/media/events" */))
const _71d180e4 = () => interopDefault(import('../pages/media/member.vue' /* webpackChunkName: "pages/media/member" */))
const _a10c977a = () => interopDefault(import('../pages/media-detail.vue' /* webpackChunkName: "pages/media-detail" */))
const _7dc2b73c = () => interopDefault(import('../pages/meeting.vue' /* webpackChunkName: "pages/meeting" */))
const _aa33b13c = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _4e995af3 = () => interopDefault(import('../pages/prices.vue' /* webpackChunkName: "pages/prices" */))
const _028396cd = () => interopDefault(import('../pages/products.vue' /* webpackChunkName: "pages/products" */))
const _2b250f90 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _3588884c = () => interopDefault(import('../pages/products/search.vue' /* webpackChunkName: "pages/products/search" */))
const _005d7cd0 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7a6b6951 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _48fe6e72 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _3209dce4 = () => interopDefault(import('../pages/company/news.vue' /* webpackChunkName: "pages/company/news" */))
const _07a9f8d7 = () => interopDefault(import('../pages/email/verification.vue' /* webpackChunkName: "pages/email/verification" */))
const _4aca5735 = () => interopDefault(import('../pages/email/verify.vue' /* webpackChunkName: "pages/email/verify" */))
const _047e7d51 = () => interopDefault(import('../pages/page/the-greatest-mice-tourism-event-list.vue' /* webpackChunkName: "pages/page/the-greatest-mice-tourism-event-list" */))
const _159eb16d = () => interopDefault(import('../pages/user/calculator.vue' /* webpackChunkName: "pages/user/calculator" */))
const _5296bbc4 = () => interopDefault(import('../pages/user/impersonate.vue' /* webpackChunkName: "pages/user/impersonate" */))
const _28bc2e64 = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _2529ee2b = () => interopDefault(import('../pages/user/login/successful.vue' /* webpackChunkName: "pages/user/login/successful" */))
const _54a78d16 = () => interopDefault(import('../pages/user/logout.vue' /* webpackChunkName: "pages/user/logout" */))
const _5ea592e4 = () => interopDefault(import('../pages/user/registration.vue' /* webpackChunkName: "pages/user/registration" */))
const _606354dc = () => interopDefault(import('../pages/user/ambassador/academy/index.vue' /* webpackChunkName: "pages/user/ambassador/academy/index" */))
const _7b9d527c = () => interopDefault(import('../pages/user/provider/complete.vue' /* webpackChunkName: "pages/user/provider/complete" */))
const _3a5b5de5 = () => interopDefault(import('../pages/user/provider/master.vue' /* webpackChunkName: "pages/user/provider/master" */))
const _55020994 = () => interopDefault(import('../pages/user/provider/profile.vue' /* webpackChunkName: "pages/user/provider/profile" */))
const _047ea254 = () => interopDefault(import('../pages/user/provider/profile/academy/index.vue' /* webpackChunkName: "pages/user/provider/profile/academy/index" */))
const _cd68100a = () => interopDefault(import('../pages/user/provider/profile/associations.vue' /* webpackChunkName: "pages/user/provider/profile/associations" */))
const _24bd6b22 = () => interopDefault(import('../pages/user/provider/profile/certifications.vue' /* webpackChunkName: "pages/user/provider/profile/certifications" */))
const _8e625b0a = () => interopDefault(import('../pages/user/provider/profile/dashboard.vue' /* webpackChunkName: "pages/user/provider/profile/dashboard" */))
const _93870898 = () => interopDefault(import('../pages/user/provider/profile/dashboard.old.vue' /* webpackChunkName: "pages/user/provider/profile/dashboard.old" */))
const _529edfb6 = () => interopDefault(import('../pages/user/provider/profile/fairs.vue' /* webpackChunkName: "pages/user/provider/profile/fairs" */))
const _80c8a562 = () => interopDefault(import('../pages/user/provider/profile/invoices.vue' /* webpackChunkName: "pages/user/provider/profile/invoices" */))
const _49d3c501 = () => interopDefault(import('../pages/user/provider/profile/company/additional-information.vue' /* webpackChunkName: "pages/user/provider/profile/company/additional-information" */))
const _77ce29c6 = () => interopDefault(import('../pages/user/provider/profile/company/contact-person.vue' /* webpackChunkName: "pages/user/provider/profile/company/contact-person" */))
const _5afb74fd = () => interopDefault(import('../pages/user/provider/profile/company/general.vue' /* webpackChunkName: "pages/user/provider/profile/company/general" */))
const _685e257a = () => interopDefault(import('../pages/user/provider/profile/company/keyfacts.vue' /* webpackChunkName: "pages/user/provider/profile/company/keyfacts" */))
const _38c290d8 = () => interopDefault(import('../pages/user/provider/profile/company/meeting-requests/index.vue' /* webpackChunkName: "pages/user/provider/profile/company/meeting-requests/index" */))
const _28f94282 = () => interopDefault(import('../pages/user/provider/profile/company/social-media.vue' /* webpackChunkName: "pages/user/provider/profile/company/social-media" */))
const _ced87e58 = () => interopDefault(import('../pages/user/provider/profile/company/verified-member.vue' /* webpackChunkName: "pages/user/provider/profile/company/verified-member" */))
const _4a750e1c = () => interopDefault(import('../pages/user/provider/profile/gmcs/status.vue' /* webpackChunkName: "pages/user/provider/profile/gmcs/status" */))
const _b85d474c = () => interopDefault(import('../pages/user/provider/profile/gmcs/survey.vue' /* webpackChunkName: "pages/user/provider/profile/gmcs/survey" */))
const _28816b58 = () => interopDefault(import('../pages/user/provider/profile/listings/case-studies/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/case-studies/index" */))
const _a56d22a8 = () => interopDefault(import('../pages/user/provider/profile/listings/destinations.vue' /* webpackChunkName: "pages/user/provider/profile/listings/destinations" */))
const _64676b5d = () => interopDefault(import('../pages/user/provider/profile/listings/jobs.vue' /* webpackChunkName: "pages/user/provider/profile/listings/jobs" */))
const _cd72a346 = () => interopDefault(import('../pages/user/provider/profile/listings/news/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/news/index" */))
const _724b042e = () => interopDefault(import('../pages/user/provider/profile/listings/products/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/products/index" */))
const _54670171 = () => interopDefault(import('../pages/user/provider/profile/listings/profiles.vue' /* webpackChunkName: "pages/user/provider/profile/listings/profiles" */))
const _3b2b7d62 = () => interopDefault(import('../pages/user/provider/profile/listings/references.vue' /* webpackChunkName: "pages/user/provider/profile/listings/references" */))
const _5ea1a065 = () => interopDefault(import('../pages/user/provider/profile/settings/add-coupon.vue' /* webpackChunkName: "pages/user/provider/profile/settings/add-coupon" */))
const _6565149e = () => interopDefault(import('../pages/user/provider/profile/settings/change-mail.vue' /* webpackChunkName: "pages/user/provider/profile/settings/change-mail" */))
const _9e2050d6 = () => interopDefault(import('../pages/user/provider/profile/settings/change-password.vue' /* webpackChunkName: "pages/user/provider/profile/settings/change-password" */))
const _34e856cc = () => interopDefault(import('../pages/user/provider/profile/settings/newsletter.vue' /* webpackChunkName: "pages/user/provider/profile/settings/newsletter" */))
const _272745f3 = () => interopDefault(import('../pages/user/provider/profile/settings/update-credit-card.vue' /* webpackChunkName: "pages/user/provider/profile/settings/update-credit-card" */))
const _0dbadf6c = () => interopDefault(import('../pages/user/provider/profile/listings/news/add.vue' /* webpackChunkName: "pages/user/provider/profile/listings/news/add" */))
const _c3bb4412 = () => interopDefault(import('../pages/user/provider/profile/academy/course/detail/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/academy/course/detail/_id/index" */))
const _3902b6be = () => interopDefault(import('../pages/user/provider/profile/academy/course/lessons/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/academy/course/lessons/_id/index" */))
const _d0d77eac = () => interopDefault(import('../pages/user/provider/profile/company/meeting-requests/detail/_id.vue' /* webpackChunkName: "pages/user/provider/profile/company/meeting-requests/detail/_id" */))
const _1eb19f34 = () => interopDefault(import('../pages/user/provider/profile/listings/case-studies/edit/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/case-studies/edit/_id/index" */))
const _50c2790c = () => interopDefault(import('../pages/user/provider/profile/listings/news/edit/_id.vue' /* webpackChunkName: "pages/user/provider/profile/listings/news/edit/_id" */))
const _a0742868 = () => interopDefault(import('../pages/user/provider/profile/listings/products/edit/_id/index.vue' /* webpackChunkName: "pages/user/provider/profile/listings/products/edit/_id/index" */))
const _acb46306 = () => interopDefault(import('../pages/user/ambassador/academy/course/detail/_id/index.vue' /* webpackChunkName: "pages/user/ambassador/academy/course/detail/_id/index" */))
const _486944db = () => interopDefault(import('../pages/user/ambassador/academy/course/lessons/_id/index.vue' /* webpackChunkName: "pages/user/ambassador/academy/course/lessons/_id/index" */))
const _99377a8c = () => interopDefault(import('../pages/meeting-request/detail/_id/index.vue' /* webpackChunkName: "pages/meeting-request/detail/_id/index" */))
const _4ca7842c = () => interopDefault(import('../pages/meeting-request/response/_id/index.vue' /* webpackChunkName: "pages/meeting-request/response/_id/index" */))
const _55c3518e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _95330718,
    meta: {"module":"about","showNavSearch":true},
    name: "about"
  }, {
    path: "/academy",
    component: _3a5eb893,
    children: [{
      path: "/academy",
      component: _6a7544d6,
      meta: {"module":"academy","showNavSearch":true},
      name: "academy"
    }, {
      path: "courses",
      component: _e59f1c88,
      meta: {"module":"academy","showNavSearch":true},
      name: "academy.courses"
    }, {
      path: "course/detail/:id?/:slug",
      component: _e29f4c1c,
      meta: {"module":"academy","showNavSearch":true},
      props: true,
      name: "academy.course.detail"
    }, {
      path: "course/watch/:id?/:slug",
      component: _cc1be4a0,
      meta: {"module":"academy","showNavSearch":true,"layout":"academy"},
      props: true,
      name: "academy.course.watch"
    }, {
      path: "/academy/course/lessons/:id/:slug",
      component: _e29f4c1c,
      meta: {"module":"academy","showNavSearch":true},
      props: route => ({ isLessonsRoute: true, id: route.params.id, slug: route.params.slug }),
      name: "academy.course.lessons"
    }]
  }, {
    path: "/buyerprograms",
    component: _015cceee,
    meta: {"module":"buyerprograms","showNavSearch":true},
    name: "buyerprograms"
  }, {
    path: "/contact",
    component: _0f93f872,
    meta: {"module":"contact","showNavSearch":true},
    name: "page.contact"
  }, {
    path: "/directory",
    component: _007407d4,
    meta: {"module":"directory","showNavSearch":false},
    name: "directory"
  }, {
    path: "/event-detail",
    component: _91cdf866,
    meta: {"showNavSearch":true},
    name: "event-detail"
  }, {
    path: "/faq",
    component: _74124186,
    children: [{
      path: "/faq",
      component: _31bc4400,
      meta: {"module":"faq","showNavSearch":true},
      name: "faq"
    }, {
      path: "search",
      component: _9ce9d52c,
      meta: {"module":"faq","showNavSearch":true},
      name: "faq-search"
    }, {
      path: "/faq/:category(provider|buyer)",
      component: _31bc4400,
      meta: {"module":"faq","showNavSearch":true},
      props: true,
      name: "faq/faq/:category(provider|buyer)"
    }]
  }, {
    path: "/media",
    component: _f656756a,
    children: [{
      path: "/media",
      component: _54ace78e,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      name: "media"
    }, {
      path: "events",
      component: _52bf3e8d,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      name: "media-events"
    }, {
      path: "member",
      component: _71d180e4,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      name: "media-member"
    }, {
      path: "/media/page/:page(\\d+)",
      component: _54ace78e,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media/media/page/:page(\\d+)"
    }, {
      path: "/media/:category(news|articles|videos)",
      component: _54ace78e,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media/media/:category(news|articles|videos)"
    }, {
      path: "/media/news/page/:page(\\d+)",
      component: _54ace78e,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: route => ({ category: 'news', page: route.params.page }),
      name: "media/media/news/page/:page(\\d+)"
    }, {
      path: "/media/articles/page/:page(\\d+)",
      component: _54ace78e,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: route => ({ category: 'articles', page: route.params.page }),
      name: "media/media/articles/page/:page(\\d+)"
    }, {
      path: "/media/videos/page/:page(\\d+)",
      component: _54ace78e,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: route => ({ category: 'videos', page: route.params.page }),
      name: "media/media/videos/page/:page(\\d+)"
    }, {
      path: "/media/member/page/:page",
      component: _71d180e4,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media-member/media/member/page/:page"
    }, {
      path: "/media/member",
      component: _71d180e4,
      meta: {"module":"media","wrapperClass":"services-page","showNavSearch":true},
      props: true,
      name: "media-member/media/member"
    }]
  }, {
    path: "/media-detail",
    component: _a10c977a,
    meta: {"module":"media","showNavSearch":true},
    name: "media-detail"
  }, {
    path: "/meeting",
    component: _7dc2b73c,
    name: "meeting"
  }, {
    path: "/newsletter",
    component: _aa33b13c,
    meta: {"module":"newsletter","showNavSearch":true},
    name: "page.newsletter"
  }, {
    path: "/prices",
    component: _4e995af3,
    meta: {"module":"prices","showNavSearch":true,"noWrapperClass":true},
    name: "Prices"
  }, {
    path: "/products",
    component: _028396cd,
    children: [{
      path: "/products",
      component: _2b250f90,
      meta: {"module":"products","showNavSearch":false,"wrapperClass":"products-wrap"},
      name: "products"
    }, {
      path: "search",
      component: _3588884c,
      meta: {"module":"products","showNavSearch":false,"wrapperClass":"products-wrap"},
      name: "products.search"
    }, {
      path: "/products/page/:page(\\d+)",
      component: _2b250f90,
      meta: {"module":"products","showNavSearch":false,"wrapperClass":"products-wrap"},
      props: true,
      name: "products/products/page/:page(\\d+)"
    }]
  }, {
    path: "/profile/:id/:slug",
    component: _005d7cd0,
    meta: {"module":"provider.public","noWrapperClass":true},
    props: true,
    name: "provider.public.profile"
  }, {
    path: "/search",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    name: "search"
  }, {
    path: "/services",
    component: _48fe6e72,
    meta: {"module":"services","showNavSearch":true},
    name: "services"
  }, {
    path: "/company/news",
    component: _3209dce4,
    meta: {"module":"profile","showNavSearch":true},
    name: "company-news"
  }, {
    path: "/email/verification",
    component: _07a9f8d7,
    meta: {"module":"register"},
    name: "email-verification"
  }, {
    path: "/email/verify",
    component: _4aca5735,
    meta: {"module":"register"},
    name: "verification.notice"
  }, {
    path: "/page/the-greatest-mice-tourism-event-list",
    component: _047e7d51,
    name: "page-the-greatest-mice-tourism-event-list"
  }, {
    path: "/user/calculator",
    component: _159eb16d,
    meta: {"module":"prices","showNavSearch":true,"noWrapperClass":true},
    name: "user.calculator"
  }, {
    path: "/user/impersonate",
    component: _5296bbc4,
    meta: {"module":"login"},
    name: "user.impersonate"
  }, {
    path: "/user/login",
    component: _28bc2e64,
    meta: {"module":"login"},
    name: "user.login",
    children: [{
      path: "successful",
      component: _2529ee2b,
      meta: {"module":"login"},
      name: "user.login.successful"
    }]
  }, {
    path: "/user/logout",
    component: _54a78d16,
    meta: {"module":"logout"},
    name: "user.logout"
  }, {
    path: "/user/registration",
    component: _5ea592e4,
    meta: {"module":"registration"},
    name: "user.registration"
  }, {
    path: "/user/ambassador/academy",
    component: _606354dc,
    meta: {"module":"ambassador","showNavSearch":false,"hideFooter":true},
    name: "ambassador.academy"
  }, {
    path: "/user/provider/complete",
    component: _7b9d527c,
    meta: {"module":"profile","onProfile":false},
    name: "user.provider.complete"
  }, {
    path: "/user/provider/master",
    component: _3a5b5de5,
    meta: {"module":"master","wrapperClass":"profiles"},
    name: "provider.master"
  }, {
    path: "/user/provider/profile",
    component: _55020994,
    name: "user-provider-profile",
    children: [{
      path: "academy",
      component: _047ea254,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles light"},
      name: "provider.profile.academy.courses"
    }, {
      path: "associations",
      component: _cd68100a,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.associations"
    }, {
      path: "certifications",
      component: _24bd6b22,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.certifications"
    }, {
      path: "dashboard",
      component: _8e625b0a,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.dashboard"
    }, {
      path: "dashboard.old",
      component: _93870898,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.dashboard.old"
    }, {
      path: "fairs",
      component: _529edfb6,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.fairs"
    }, {
      path: "invoices",
      component: _80c8a562,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.invoices"
    }, {
      path: "company/additional-information",
      component: _49d3c501,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.additional-information"
    }, {
      path: "company/contact-person",
      component: _77ce29c6,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.contact-person"
    }, {
      path: "company/general",
      component: _5afb74fd,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.general"
    }, {
      path: "company/keyfacts",
      component: _685e257a,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.keyfacts"
    }, {
      path: "company/meeting-requests",
      component: _38c290d8,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.meeting-requests"
    }, {
      path: "company/social-media",
      component: _28f94282,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.social-media"
    }, {
      path: "company/verified-member",
      component: _ced87e58,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.company.verified-member"
    }, {
      path: "gmcs/status",
      component: _4a750e1c,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.gmcs.status"
    }, {
      path: "gmcs/survey",
      component: _b85d474c,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles","hideFooter":true,"isGmcsSurvey":true},
      name: "provider.profile.gmcs.survey"
    }, {
      path: "listings/case-studies",
      component: _28816b58,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.case-studies"
    }, {
      path: "listings/destinations",
      component: _a56d22a8,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.destinations"
    }, {
      path: "listings/jobs",
      component: _64676b5d,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.jobs"
    }, {
      path: "listings/news",
      component: _cd72a346,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.news"
    }, {
      path: "listings/products",
      component: _724b042e,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.products"
    }, {
      path: "listings/profiles",
      component: _54670171,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.profiles"
    }, {
      path: "listings/references",
      component: _3b2b7d62,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.references"
    }, {
      path: "settings/add-coupon",
      component: _5ea1a065,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.add-coupon"
    }, {
      path: "settings/change-mail",
      component: _6565149e,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.change-mail"
    }, {
      path: "settings/change-password",
      component: _9e2050d6,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.change-password"
    }, {
      path: "settings/newsletter",
      component: _34e856cc,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.newsletter"
    }, {
      path: "settings/update-credit-card",
      component: _272745f3,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.settings.update-credit-card"
    }, {
      path: "listings/news/add",
      component: _0dbadf6c,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      name: "provider.profile.listings.news.add"
    }, {
      path: "academy/course/detail/:id",
      component: _c3bb4412,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles light"},
      props: true,
      name: "provider.profile.academy.course.detail"
    }, {
      path: "academy/course/lessons/:id",
      component: _3902b6be,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles light"},
      props: true,
      name: "provider.profile.academy.course.lessons"
    }, {
      path: "company/meeting-requests/detail/:id",
      component: _d0d77eac,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.meeting-requests.detail"
    }, {
      path: "listings/case-studies/edit/:id",
      component: _1eb19f34,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.listings.case-studies.edit"
    }, {
      path: "listings/news/edit/:id?",
      component: _50c2790c,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.listings.news.edit"
    }, {
      path: "listings/products/edit/:id",
      component: _a0742868,
      meta: {"module":"profile","onProfile":true,"wrapperClass":"profiles"},
      props: true,
      name: "provider.profile.listings.products.edit"
    }]
  }, {
    path: "/user/ambassador/academy/course/detail/:id",
    component: _acb46306,
    meta: {"module":"ambassador","showNavSearch":false,"hideFooter":true},
    name: "ambassador.academy.course.detail"
  }, {
    path: "/user/ambassador/academy/course/lessons/:id",
    component: _486944db,
    meta: {"module":"ambassador","showNavSearch":false,"hideFooter":true},
    name: "ambassador.academy.course.lessons"
  }, {
    path: "/meeting-request/detail/:id",
    component: _99377a8c,
    meta: {"module":"search","onProfile":false},
    props: true,
    name: "meeting-request.detail"
  }, {
    path: "/meeting-request/response/:id",
    component: _4ca7842c,
    meta: {"module":"search","onProfile":false},
    props: true,
    name: "meeting-request.response"
  }, {
    path: "/",
    component: _55c3518e,
    meta: {"module":"index"},
    name: "index"
  }, {
    path: "/media/event/:id(\\d+)/:slug",
    component: _91cdf866,
    meta: {"showNavSearch":true},
    props: true,
    name: "media.event.detail"
  }, {
    path: "/media/:id(\\d+)/:slug",
    component: _a10c977a,
    meta: {"module":"media","showNavSearch":true},
    props: true,
    name: "media.news.detail"
  }, {
    path: "/meeting/:id",
    component: _7dc2b73c,
    props: route => ({ id: route.params.id, moderator: false }),
    name: "meeting.participant"
  }, {
    path: "/meeting/moderator/:id",
    component: _7dc2b73c,
    props: route => ({ id: route.params.id, moderator: true }),
    name: "meeting.moderator"
  }, {
    path: "/profile/:id/:slug/:destinationId",
    component: _005d7cd0,
    meta: {"module":"provider.public","noWrapperClass":true},
    props: true,
    name: "provider.public.profile.destination.id"
  }, {
    path: "/profile/:id/:slug/:destinationId/:destinationSlug",
    component: _005d7cd0,
    meta: {"module":"provider.public","noWrapperClass":true},
    props: true,
    name: "provider.public.profile.destination.slug"
  }, {
    path: "/search/:type",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: true,
    name: "search.type.name"
  }, {
    path: "/search/:type/page/:page",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: true,
    name: "search.type.name.page"
  }, {
    path: "/search/country/:term/:slug",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'country', term: route.params.term, slug: route.params.slug }),
    name: "search.type.country"
  }, {
    path: "/search/country/:term/:slug/page/:page",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'country', term: route.params.term, slug: route.params.slug, page: route.params.page }),
    name: "search.type.country.page"
  }, {
    path: "/search/city/:country/:slug/:term",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'city', country: route.params.country, slug: route.params.slug, term: route.params.term,  }),
    name: "search.type.city"
  }, {
    path: "/search/city/:country/:slug/:term/page/:page",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ type: 'city', country: route.params.country, slug: route.params.slug, term: route.params.term, page: route.params.page }),
    name: "search.type.city.page"
  }, {
    path: "/in/country/:country/:slug",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ country: route.params.country, slug: route.params.slug, type: 'country', seo: true }),
    name: "search.seo.in.country"
  }, {
    path: "/in/country/:country/:slug/page/:page",
    component: _7a6b6951,
    meta: {"module":"search","noWrapperClass":true},
    props: route => ({ country: route.params.country, slug: route.params.slug, page: route.params.page, type: 'country', seo: true }),
    name: "search.seo.in.country.page"
  }, {
    path: "/company/news/:companySlug/:id/:slug",
    component: _3209dce4,
    meta: {"module":"profile","showNavSearch":true},
    props: true,
    name: "company.news.detail"
  }, {
    path: "/email/verification/:id/:hash",
    component: _07a9f8d7,
    meta: {"module":"register"},
    props: true,
    name: "email.verify.verification"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
