
export const helper = {

    methods: {

        cdnUrl: function (asset) {

            return false;

            const useCDN = this.$store.state.config.useCDN

            if(useCDN === true) {
                const cdnUrl = this.$store.state.config.cdnUrl
                asset = cdnUrl + asset;
            }

            return asset;
        },

        userIs: function (type) {
            if(this.authed === false) {
                return false
            }
            return this.$store.state.auth.user.type === type
        },

        profileIs: function (type) {
            if(this.authed === false) {
                return false
            }
            return this.$store.state.auth.user.profile.type === type
        },

        featureEnabled(feature) {

            if(this.features[feature] && this.features[feature] === true) {
                return true
            }

            return false
        },

    },

    computed: {

        authed: function () {
            return this.$store.state.auth.loggedIn
        },

        userVerified: function () {
            return this.$store.state.auth.user.verified
        },

        userRegCompleted: function () {
            return this.$store.state.auth.user.registrationComplete
        },

        userIsMaster: function () {
            return this.$store.state.auth.user.isMaster
        },

        userHasCompanyProfile: function () {
            return this.$store.state.auth.user.hasCompanyProfile
        },

        userType: function () {
            return this.$store.state.auth.user.type
        },

        profileType: function () {
            return this.$store.state.auth.user.profile.type
        },

        isOnProfile: function () {
            return this.$store.state.onProfile
        },

        profile: function () {
            return this.$store.state.auth.user.profile
        },

        isCrawler() {
            return this.$store.state.isCrawler
        },

        features: function () {
            return this.$store.state.features
        },

    }

}
