import constant from './constant'

let lazyImageObserver = null

const clearDataBg = (lazyImage, stateClass) => {
    lazyImage.classList.add(stateClass)

    lazyImage.removeAttribute('data-bg')
    lazyImage.removeAttribute('data-err')
}

if ('IntersectionObserver' in window) {
    lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                const lazyImage = entry.target
                lazyImage.classList.add(constant._V_LOADING)

                const dataBg = lazyImage.dataset.bg
                const dataErr = lazyImage.dataset.err

                var newImage = new Image()
                newImage.src = dataBg
                // when success
                newImage.onload = function () {
                    lazyImage.classList.remove(constant._V_LOADING)

                    if (dataBg) {
                        lazyImage.style.backgroundImage = 'url(' + dataBg + ')'
                        clearDataBg(lazyImage, constant._V_LOADED)
                    }
                }
                // when error
                newImage.onerror = function () {
                    lazyImage.classList.remove(constant._V_LOADING)

                    if (dataErr) {
                        lazyImage.src = dataErr
                        clearDataBg(lazyImage, constant._V_ERROR)
                    }
                }

                lazyImageObserver.unobserve(lazyImage)
            }
        })
    })
}

export default lazyImageObserver