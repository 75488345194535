

import {BInputGroup, BFormInput, BDropdown, BDropdownItem} from 'bootstrap-vue'

const ADDRESS_COMPONENTS = {
    subpremise : 'long_name',
    street_number: 'long_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    administrative_area_level_2: 'long_name',
    country: 'long_name',
    postal_code: 'long_name'
};

const CITIES_TYPE = ['locality', 'administrative_area_level_3'];
const REGIONS_TYPE = ['locality', 'sublocality', 'postal_code', 'country',
    'administrative_area_level_1', 'administrative_area_level_2'];

export default {

    name: 'GoogleAutocompleteMixin',

    components: { BInputGroup, BFormInput, BDropdown, BDropdownItem },

    props: {
        id: {
            type: String,
            required: true
        },

        classname: String,

        placeholder: {
            type: String,
            default: 'Start typing'
        },

        types: {
            type: String,
            default: 'address'
        },

        country: {
            type: [String, Array],
            default: null
        },

        enableGeolocation: {
            type: Boolean,
            default: false
        },

        geolocationOptions: {
            type: Object,
            default: null
        },

        loadApi: {
            type: Boolean,
            default: true
        },

        type: {
            type: String,
            default: 'location'
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        loader: {
            type: Boolean,
            default: false,
        },

        iconLoader: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            /**
             * The Autocomplete object.
             *
             * @type {Autocomplete}
             * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
             */
            autocomplete: null,

            /**
             * Autocomplete input text
             * @type {String}
             */
            autocompleteText: '',

            geolocation: {
                /**
                 * Google Geocoder Objet
                 * @type {Geocoder}
                 * @link https://developers.google.com/maps/documentation/javascript/reference#Geocoder
                 */
                geocoder: null,

                /**
                 * Filled after geolocate result
                 * @type {Coordinates}
                 * @link https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
                 */
                loc: null,

                /**
                 * Filled after geolocate result
                 * @type {Position}
                 * @link https://developer.mozilla.org/en-US/docs/Web/API/Position
                 */
                position: null
            },
            searchType: 'location',
            nameText: '',
            isFocus: false,
            loaderThrottle: 200,
            initialized: false,
            inputEl: null,
            pacObserver: null,
            pacLoaded: false,

        }
    },

    watch: {
        autocompleteText: function (newVal, oldVal) {
            this.$emit('inputChange', { newVal, oldVal }, this.id);
        },
        country: function(newVal, oldVal) {
            this.autocomplete.setComponentRestrictions({
                country: this.country === null ? [] : this.country
            });
        },
        loadApi: function (newVal, oldVal) {
            if(newVal === true) {
                this.initApi()
            }
        },
        isDisabled: function (newVal, oldVal) {
            if(newVal === false) {

                if(this.loader && this.isFocus) {
                    this.$nextTick(() => {
                        this.focus()
                    })
                }

            }
        }
    },

    created: function() {
        this.searchType = this.type
        this.$bus.$on('google-place-api/loaded', this.onApiLoadedEvent)
    },

    destroyed() {
        this.removeApiLoadedListener()
    },

    mounted: function() {

        this.startPacObserver()

        if(this.loadApi === true ) {
            this.initApi()
        }

    },

    methods: {

        startPacObserver() {

             this.pacObserver = new MutationObserver(mutations => {

                const pacFound = document.querySelectorAll('div.pac-container.pac-logo').length > 0

                if (pacFound) {

                    this.pacObserver.disconnect();

                    if(this.isFocus && this.inputEl !== null) {
                        this.inputEl.blur()
                        this.inputEl.focus()
                    }

                    this.pacLoaded = true
                }
            })

            // Start observing
            this.pacObserver.observe(document.body, { //document.body is node target to observe
                childList: true, //This is a must have for the observer with subtree
                subtree: false //Set to true if changes must also be observed in descendants.
            });


        },

        onApiLoadedEvent() {

            //console.log('onApiLoadedEvent')

            this.removeApiLoadedListener()
            this.initAutocomplete()

        },

        removeApiLoadedListener() {
            //console.log('removeApiLoadedListener')
            this.$bus.$off('google-place-api/loaded', this.onApiLoadedEvent)
        },

        randomStr() {
            return Math.random().toString(36).substring(7)
        },

        onType(type) {
            this.autocompleteText = ''
            this.nameText = ''
            this.searchType = type
            this.$emit('type-changed', type)
        },

        onNameChange() {
            this.$emit('name-changed', this.nameText);
        },

        onKeyUpEnter(event) {
            this.$emit('keyup-enter', this.id);
        },

        onKeyDownEnterAuto(event) {

            var suggestion_selected = document.querySelectorAll('.pac-item-selected').length > 0

            if(suggestion_selected) {
                return
            }

            this.inputEl.dispatchEvent(
                new KeyboardEvent("keydown", {
                    keyCode: 40,
                    which: 40,
                })
            )

        },

        async initApi() {

            this.onApiLoadStart()

            if(this.$googleApi.isLoaded() === false) {
                this.removeApiLoadedListener()
                await this.$googleApi.load()
            }

           this.initAutocomplete()

            this.onApiLoadEnd()
        },


        initAutocomplete() {

            //console.log('initAutocomplete')

            if(this.initialized) {
                return
            }

            const options = {};

            // IMPORTANT: To avoid paying for data that you don't need,
            // be sure to use Autocomplete.setFields() to specify only the place data that you will use.
            options.fields = ['address_components', 'geometry']

            if (this.types) {
                options.types = [this.types];
            }

            if (this.country) {
                options.componentRestrictions = {
                    country: this.country
                };
            }

            this.inputEl = document.getElementById(this.id)

            this.autocomplete = new google.maps.places.Autocomplete(
                this.inputEl,
                options
            );

            this.autocomplete.addListener('place_changed', this.onPlaceChanged);

            this.initialized = true
        },

        onApiLoadStart() {

            if(this.loader) {
                this.$waiter.throttle('overlay', this.loaderThrottle)
            }

        },

        onApiLoadEnd() {

            if(this.loader) {
                this.$waiter.end('overlay')
            }

        },

        /**
         * When a place changed
         */
        onPlaceChanged() {
            let place = this.autocomplete.getPlace();

            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                this.$emit('no-results-found', place, this.id);
                return;
            }

            if (place.address_components !== undefined) {
                // return returnData object and PlaceResult object
                this.$emit('placechanged', this.formatResult(place), place, this.id);

                // update autocompleteText then emit change event
                this.autocompleteText = document.getElementById(this.id).value
                this.onChange()
            }
        },

        /**
         * When the input gets focus
         */
        onFocus() {
            this.biasAutocompleteLocation();
            this.$emit('focus');
            this.isFocus = true
        },

        /**
         * When the input loses focus
         */
        onBlur() {
            this.$emit('blur');
            //this.isFocus = false
        },

        /**
         * When the input got changed
         */
        onChange() {
            this.$emit('change', this.autocompleteText);
        },

        /**
         * When a key gets pressed
         * @param  {Event} event A keypress event
         */
        onKeyPress(event) {
            this.$emit('keypress', event);
        },

        /**
         * When a keyup occurs
         * @param  {Event} event A keyup event
         */
        onKeyUp(event) {
            this.$emit('keyup', event);
        },

        /**
         * Clear the input
         */
        clear() {
            this.autocompleteText = ''
        },

        /**
         * Focus the input
         */
        focus() {
            this.$refs.autocomplete.focus()
        },

        /**
         * Blur the input
         */
        blur() {
            this.$refs.autocomplete.blur()
        },

        /**
         * Update the value of the input
         * @param  {String} value
         */
        update (value) {
            this.autocompleteText = value
        },

        /**
         * Update the coordinates of the input
         * @param  {Coordinates} value
         */
        updateCoordinates (value) {
            if (!value && !(value.lat || value.lng)) return;
            if (!this.geolocation.geocoder) this.geolocation.geocoder = new google.maps.Geocoder();
            this.geolocation.geocoder.geocode({'location': value}, (results, status) => {
                if (status === 'OK') {
                    results = this.filterGeocodeResultTypes(results);
                    if (results[0]) {
                        this.$emit('placechanged', this.formatResult(results[0]), results[0], this.id);
                        this.update(results[0].formatted_address);
                    } else {
                        this.$emit('error', 'no result for provided coordinates');
                    }
                } else {
                    this.$emit('error', 'error getting address from coords');
                }
            })
        },

        /**
         * Update location based on navigator geolocation
         */
        geolocate () {
            this.updateGeolocation ((geolocation, position) => {
                this.updateCoordinates(geolocation)
            })
        },

        /**
         * Update internal location from navigator geolocation
         * @param  {Function} (geolocation, position)
         */
        updateGeolocation (callback = null) {
            if (navigator.geolocation) {
                let options = {};
                if(this.geolocationOptions) Object.assign(options, this.geolocationOptions);
                navigator.geolocation.getCurrentPosition(position => {
                    let geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    this.geolocation.loc = geolocation;
                    this.geolocation.position = position;

                    if (callback) callback(geolocation, position);
                }, err => {
                    this.$emit('error', 'Cannot get Coordinates from navigator', err);
                }, options);
            }
        },


        // Bias the autocomplete object to the user's geographical location,
        // as supplied by the browser's 'navigator.geolocation' object.
        biasAutocompleteLocation () {
            if (this.enableGeolocation) {
                this.updateGeolocation((geolocation, position) => {
                    let circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                    this.autocomplete.setBounds(circle.getBounds());
                })
            }
        },

        /**
         * Format result from Geo google APIs
         * @param place
         * @returns {{formatted output}}
         */
        formatResult (place) {
            let returnData = {};
            for (let i = 0; i < place.address_components.length; i++) {
                let addressType = place.address_components[i].types[0];

                if (ADDRESS_COMPONENTS[addressType]) {
                    let long = place.address_components[i]['long_name'];
                    let short = place.address_components[i]['short_name'];
                    returnData[addressType] = long;
                    returnData[addressType + '_short'] = short;
                }
            }

            returnData['latitude'] = place.geometry.location.lat();
            returnData['longitude'] = place.geometry.location.lng();
            return returnData
        },

        /**
         * Extract configured types out of raw result as
         * Geocode API does not allow to do it
         * @param results
         * @returns {GeocoderResult}
         * @link https://developers.google.com/maps/documentation/javascript/reference#GeocoderResult
         */
        filterGeocodeResultTypes (results) {
            if (!results || !this.types) return results;
            let output = [];
            let types = [this.types];
            if (types.includes('(cities)')) types = types.concat(CITIES_TYPE);
            if (types.includes('(regions)')) types = types.concat(REGIONS_TYPE);

            for (let r of results) {
                for (let t of r.types) {
                    if (types.includes(t)) {
                        output.push(r);
                        break;
                    }
                }
            }
            return output;
        },

        _pacSelectFirst(input) {

            // store the original event binding function
            var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;

            function addEventListenerWrapper(type, listener) {
                // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
                // and then trigger the original listener.

                if (type == "keydown") {
                    var orig_listener = listener;
                    listener = function (event) {
                        if (event.which == 13 || event.keyCode == 13) {
                            var suggestion_selected = document.querySelectorAll('.pac-item-selected').length > 0;
                            if(!suggestion_selected){
                                var simulated_downarrow = new KeyboardEvent("keydown", {keyCode:40, which:40});
                                orig_listener.apply(input, [simulated_downarrow]);
                            }
                        }

                        orig_listener.apply(input, [event]);
                    };
                }

                // add the modified listener
                _addEventListener.apply(input, [type, listener]);
            }

            if (input.addEventListener)
                input.addEventListener = addEventListenerWrapper;
            else if (input.attachEvent)
                input.attachEvent = addEventListenerWrapper;

        }

    },

    computed: {

        typeText() {
            return this.$helper.capitalize(this.searchType)
        },

        isDisabled() {

            if(this.$waiter.has('overlay')) {
                return true
            }

            return this.disabled
        },

        showLoaderIcon() {

            if(this.iconLoader === false) {
                return false
            }

            if(this.isFocus === false) {
                return false
            }

            if(this.pacLoaded === true) {
                return false
            }

            return true
        }

    }
}

