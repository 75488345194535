
import LazyLoad from "vanilla-lazyload";
import defaultOptions from "~/custom/vue-vanilla-lazyload/options";
import lazy from "~/custom/vue-vanilla-lazyload/directives/lazy";
import lazyBg from "~/custom/vue-vanilla-lazyload/directives/lazy-bg";

const lazyLoadPlugin = {};

lazyLoadPlugin.install = function(Vue, options) {


    const lazyInstance = new LazyLoad(defaultOptions.lazy)
    Vue.directive("lazy", lazy(lazyInstance));

    const lazyBgInstance = new LazyLoad(defaultOptions.lazyBg)
    Vue.directive("lazy-bg", lazyBg(lazyBgInstance));


}

export default lazyLoadPlugin;
