export const state = () => ({
    company: false,
    destination: false,
    destinations: false,
    contactSelectData: false,
    news: false,
    images: false,
    products: false,
    caseStudies: false,
    masterProfiles: false,
})

export const mutations = {
    setCompany (state, value) {
        state.company = value
    },
    setDestination (state, value) {
        state.destination = value
    },
    setDestinations (state, value) {
        state.destinations = value
    },
    setContactSelectData (state, value) {

        let arr = []

        Object.keys(value).forEach(function(key) {
            arr.push({
                text: value[key],
                value: key
            })
        });

        state.contactSelectData = arr
    },
    setNews (state, value) {
        state.news = value
    },
    setImages (state, value) {
        state.images = value
    },
    setProducts (state, value) {
        state.products = value
    },
    setCaseStudies (state, value) {
        state.caseStudies = value
    },
    setMasterProfiles (state, value) {
        state.masterProfiles = value
    },

    clear (state) {
        Object.keys(state).forEach(function(key) {
            state[key] = false
        });
    }
}

export const actions = {

    CLEAR_STORE ({ commit, state}) {
        commit('clear')
    },

    GET_COMPANY ({ commit, state }, payload) {

        return new Promise((resolve, reject) => {

            let route

            switch (payload.routeType) {
                case 'destinationId':
                    route = 'api.profile.company.destination.id'
                    break
                case 'destinationSlug':
                    route = 'api.profile.company.destination.slug'
                    break
                case 'company':
                default:
                    route = 'api.profile.company'
                    break
            }

            let params = null

            if(payload.signature !== false && payload.expire !== false) {

                params = {
                    sig: payload.signature,
                    expire: payload.expire
                }

            }

            this.$axios.$get(this.$ziggy.route(route, {
                company: payload.company,
                slug: payload.slug,
                destinationId: payload.destinationId,
                destinationSlug: payload.destinationSlug
            }), { params: params } )
                .then((response) => {

                    commit('setCompany', response.company)
                    commit('setDestination', response.destination)

                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    GET_NEWS ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            if(state.news) {
                return resolve({news: state.news} )
            }

            this.$axios.$get(this.$ziggy.route('api.profile.news', {company: id}))
                .then((response) => {
                    commit('setNews', response.news)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_IMAGES ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            if(state.images) {
                return resolve({data: state.images} )
            }

            this.$axios.$get(this.$ziggy.route('api.profile.company.destination.images', {company: id}))
                .then((response) => {
                    commit('setImages', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_PRODUCTS ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            if(state.products) {
                return resolve( state.products )
            }

            this.$axios.$get(this.$ziggy.route('api.profile.products', {company: id}))
                .then((response) => {
                    commit('setProducts', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_CASE_STUDIES ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            if(state.caseStudies) {
                return resolve({data: state.caseStudies} )
            }

            this.$axios.$get(this.$ziggy.route('api.profile.case-studies', {company: id}))
                .then((response) => {
                    commit('setCaseStudies', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_DESTINATIONS ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            if(state.destinations) {
                return resolve( {data: state.destinations} )
            }

            this.$axios.$get(this.$ziggy.route('api.profile.destinations', {company: id}))
                .then((response) => {
                    commit('setDestinations', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_MASTER_PROFILES ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            if(state.masterProfiles) {
                return resolve( {data: state.masterProfiles} )
            }

            this.$axios.$get(this.$ziggy.route('api.profile.master-profiles', {company: id}))
                .then((response) => {
                    commit('setMasterProfiles', response.data)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_CONTACT_SELECT_DATA ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            if(state.contactSelectData) {
                return resolve( state.contactSelectData )
            }

            this.$axios.$get(this.$ziggy.route('api.profile.contact-select-data', {company: id}))
                .then((response) => {
                    commit('setContactSelectData', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_DESTINATION_IMAGES ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.profile.destination.images', {destination: id}))
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_DESTINATION_FILES ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.profile.destination.files', {destination: id}))
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    GET_NEWS_DATA ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.profile.news.data', {news: id}))
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

}
