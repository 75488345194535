
export default function ({ $config, $logger, $bus, store }, inject) {
    install($bus, store, $config).catch((e) => $logger.captureException(e))
}

async function install($bus, store, $config) {

    //window._mtm = window._mtm || []
    var _mtm = window._mtm = window._mtm || []
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});

    const scriptUrl = $config.matomo.tagManagerUrl

    if(store.state.ccm.widgetLoaded === true) {
        addScript(scriptUrl)
    } else {

        $bus.$on('ccm/widgetLoaded', () => {
            addScript(scriptUrl)
        })

    }

    if(store.state.tracking.trackInitialPageView === true) {
        _mtm.push({'event': 'mtm.PageView'});
        _mtm.push({'event': 'spa.pageview'});
    }

    $bus.$on('track/run', payload => {
        _mtm.push({'event': 'mtm.PageView'});
        _mtm.push({'event': 'spa.pageview'});
    })

}


function addScript(scriptUrl) {

    const { set } = window.$nuxt.$meta().addApp('matomoTagManager')

    set({
        script: [
            { src: scriptUrl, async: true  }
        ],
    })

}
