//
//
//
//
//
//


export default {

    name: 'LandingLayout',
}
