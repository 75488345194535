import Vue from 'vue'
import Toasted from 'vue-toasted'

function initToast(router) {
    const toastOptions = {"theme":"toasted-primary","iconPack":"fontawesome","position":"top-right","keepOnHover":true,"containerClass":"toasted-evintra"}
    toastOptions.router = router

    Vue.use(Toasted, toastOptions)

    const globals = [{"name":"my_error","message":(payload) => {
      if (!payload.message) {
        return "Oops.. Something Went Wrong..";
      }

      return payload.message;
    },"options":{"type":"error","duration":5000,"icon":{"name":"fa-exclamation-triangle"}}},{"name":"my_success","message":(payload) => {
      if (!payload.message) {
        return "Successful";
      }

      return payload.message;
    },"options":{"type":"success","duration":3000,"icon":{"name":"fa-check-circle"}}},{"name":"my_success_center","message":(payload) => {
      if (!payload.message) {
        return "Successful";
      }

      return payload.message;
    },"options":{"type":"success","duration":5000,"icon":{"name":"fa-check-circle"},"position":"top-center"}},{"name":"my_added","message":(payload) => {
      if (!payload.message) {
        return "Added";
      }

      return payload.message;
    },"options":{"type":"success","duration":3000,"icon":{"name":"fa-plus-circle"}}},{"name":"my_removed","message":(payload) => {
      if (!payload.message) {
        return "Removed";
      }

      return payload.message;
    },"options":{"type":"info","duration":3000,"icon":{"name":"fa-minus-circle"}}},{"name":"my_loading","message":(payload) => {
      if (!payload.message) {
        return "Loading...";
      }

      return payload.message;
    },"options":{"icon":{"name":"fa-hourglass-half"}}},{"name":"my_reload_page","message":(payload) => {
      if (!payload.message) {
        return "New release...";
      }

      return payload.message;
    },"options":{"type":"info","icon":{"name":"fa-hourglass-half"}}}]
    if(globals) {
        globals.forEach(global => {
            Vue.toasted.register(global.name, global.message, global.options)
        })
    }

    return Vue.toasted
}

export default function ({app}, inject) {
    const toasted = initToast(app.router)
    inject('toast', toasted)
}
