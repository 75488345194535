export const state = () => ({
    matomo: false,
    posthog: false,
    addthis: false,
    matomoScriptUrl: false,
    widgetLoaded: false,
    consent: false
})

export const mutations = {
    matomo (state, consented) {
        state.matomo = consented
    },
    posthog (state, consented) {
        state.posthog = consented
    },
    addthis (state, consented) {
        state.addthis = consented
    },
    matomoScriptUrl (state, url) {
        state.matomoScriptUrl = url
    },
    widgetLoaded (state, loaded) {
        state.widgetLoaded = loaded
    },
    consent (state, consent) {
        state.consent = consent
    }
}
