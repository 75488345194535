//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

    name: 'NuxtError',

    layout: 'error-layout',

    props: {
        error: {
            type: Object,
            default: null
        }
    },

    head () {
        return {
            title: this.message,
        }
    },

    created() {

        // not working because of SSR
        //this.$store.commit('setShowNavSearch', true)

        if(process.client) {
            this.$store.commit('tracking/documentTitle', '404/URL = ' +  encodeURIComponent(document.location.pathname+document.location.search) + ' From = ' + encodeURIComponent(document.referrer))
        }

    },

    computed: {
        statusCode () {
            return (this.error && this.error.statusCode) || 500
        },
        message () {
            return this.error.message || 'Sorry, the page you are looking for could not be found.'
        }
    },

}
