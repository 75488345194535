export default class {
    constructor(echo, store, bus, axios, auth) {
        this.echoInstance = echo
        this.store = store
        this.bus = bus
        this.axios = axios
        this.auth = auth

        this.reconnectInterval = 60000

        this.failStates = [
            'unavailable',
            'disconnected'
        ]

        this.initVars()

        this.pusherBindStateChange()

        this.watchAuthState()
    }

    initVars() {
        this.appSubscribed = false
        this.userSubscribed = false
        this.syncSubscribed = false

        this.appChannel = false
        this.userChannel = false
        this.syncChannel = false
    }

    pusherBindStateChange() {
        //console.log('pusherBindStateChange')

        let _this = this

        this.echoInstance.connector.pusher.connection.bind('state_change', function(states) {
            //console.log('state_changed', states)
            _this.handleStateChange(states.current)
        })

        this.handleStateChange(this.pusherState())
    }

    handleStateChange(state) {
        this.clearReconnectTimer()

        if(state === 'unavailable' || state === 'disconnected') {
            this.startReconnectTimer()
        }

        if(state === 'connected') {
            const socketId = this.echoInstance.socketId()
            this.newSocketId(socketId)

            this.pusherSubscribe()
        }
    }

    startReconnectTimer() {
        this.clearReconnectTimer()

        this.retryReconnect = setInterval(() => {
            this.pusherReconnect()
        }, this.reconnectInterval)
    }

    clearReconnectTimer() {
        clearInterval(this.retryReconnect)
    }

    pusherReconnect() {
        if(this.pusherIsFailState()) {
            this.reset()
        }
    }

    pusherSubscribe() {
        var _this = this

        this.getToken()
            .then((response) => {
                _this.subscribeAppUpdate()
                _this.subscribeUserChannel()
                _this.subscribeSyncChannel()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    pusherIsFailState() {
        var state = this.pusherState()
        var index = this.failStates.indexOf(state)

        return index !== -1
    }

    newSocketId(socketId) {
        //console.log('newSocketId', socketId)

        if(socketId) {
            this.addSocketIdAxios(socketId)
        }
    }

    reset() {
        //console.log('reset')

        this.echoInstance.disconnect()

        this.initVars()

        this.echoInstance.connect()

        this.pusherBindStateChange()
    }

    watchAuthState() {
        let _this = this

        //console.log('watchAuthState')

        this.auth.$storage.watchState('loggedIn', loggedIn => {
            //console.log('watchState', loggedIn)

            _this.reset()
        })
    }

    getToken() {
        return this.store.dispatch('echo/GET_TOKEN')
    }

    resetToken() {
        this.store.commit('setToken', false)
    }

    getSyncChannel() {
        return this.syncChannel
    }

    channel(channel) {
        return this.echoInstance.channel(channel)
    }

    channelToken(channel) {
        if(this.echoToken() === false) {
            throw new Error("Token is missing")
        }

        var channelName = channel + '.' + this.echoToken()
        return this.echoInstance.channel(channelName)
    }

    setSubscribed(type) {
        if(type === 'app') {
            this.appSubscribed = true
        }

        if(type === 'user') {
            this.userSubscribed = true
        }

        if(type === 'sync') {
            this.syncSubscribed = true
        }
    }

    subscribeSyncChannel() {
        var _this = this
        var syncChannel = 'sync'

        this.syncChannel = this.channelToken(syncChannel)
            .listen('EchoSync', function (e)
            {
                _this.bus.$emit('search/' + e.type, e.company)
            })
            .on('pusher:subscription_succeeded', (status) => {
                _this.setSubscribed('sync')
            })
    }

    subscribeAppUpdate() {
        var _this = this
        var updateChannel = 'appUpdate'

        this.appChannel = this.channel(updateChannel)
            .listen('.newRelease', (payload) => {
                //_this.reloadPageTimer()
                _this.bus.$emit('release/new')
            })
            .error((payload) => {
                /* _this.retryAppChannel = setTimeout(() => {
                     _this.subscribeAppUpdate()
                 }, _this.retryInterval)*/
            })
            .on('pusher:subscription_succeeded', (status) => {
                _this.setSubscribed('app')
            })
    }

    subscribeUserChannel() {
        var _this = this

        if(this.isAuthed()) {
            this.subscribeUser()
        } else {
            this.subscribeAnonymous()
        }
    }

    subscribeUser() {
        var _this = this
        var channel = 'App.User.' + this.userId()

        //console.log('subscribe channel', channel)

        this.userChannel = this.echoInstance.private(channel)
            .listen('.Logout', (payload) => {
                //_this.reloadPage()
                _this.onLogout()
            })
            .error((payload) => {
                /*                _this.retryUserChannel = setTimeout(() => {
                       r             _this.subscribeUser(userId)
                                }, _this.retryInterval)*/
            })
            .on('pusher:subscription_succeeded', (status) => {
                _this.setSubscribed('user')
            })
    }

    subscribeAnonymous() {
        var _this = this
        var anonChannel = 'User'

        this.userChannel = this.channelToken(anonChannel)
            .listen('.Login', (payload) => {
                _this.reloadPage()
                _this.onLogin()
            })
            .error((payload) => {
                /*                _this.retryAnonymousChannel = setTimeout(() => {
                                    _this.subscribeAnonymous(token)
                                }, _this.retryInterval)*/
            })
            .on('pusher:subscription_succeeded', (status) => {
                _this.setSubscribed('user')
            })

        //console.log('subscribe channel', this.userChannel.name)
    }

    onLogout() {
        if(this.isAuthed() === true) {
            this.auth.reset()
        } else {
            this.reset()
        }
    }

    onLogin() {
        if(this.isAuthed() === false) {
            this.auth.fetchUser()
                .then(() => {
                })
                .catch((e) => {
                })
        } else {
            this.reset()
        }
    }

    reloadPage() {
        //window.location.reload(true)
    }

    reloadPageTimer() {
        var randomInterval = this.randomTimerInterval(0, 10)

        this.timerReloadPage = setTimeout(() => {
            this.reloadPage()
        }, randomInterval)
    }

    randomTimerInterval(minSec, maxSec) {
        var random = this.getRandomIntInclusive(minSec, maxSec)
        return  random * 1000
    }

    getRandomIntInclusive(min, max) {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min +1)) + min
    }

    pusherState () {
        return this.echoInstance.connector.pusher.connection.state
    }

    echoToken() {
        return this.store.state.echo.token
    }

    userId () {
        return this.store.state.auth.user.id
    }

    isAuthed () {
        return this.store.state.auth.loggedIn
    }

    addSocketIdAxios(socketId) {
        this.axios.setHeader('X-Socket-ID', socketId)
    }
}
