
export default ({store}, inject) => {

    const waiter = {
        start: (type, storeVar = store) => start(type, storeVar),
        end: (type, storeVar = store) => end(type, storeVar),
        has: (type) => store.getters['waiter/has'](type),
        throttle: (type, time, storeVar = store) => startThrottle(type, time, storeVar)
    }

    inject('waiter', waiter)
}

function start(type, store) {
    removeThrottle(type)
    store.dispatch('waiter/start', type)
}

function end(type, store) {
    removeThrottle(type)
    store.dispatch('waiter/end', type)
}

let throttleTimeouts = []

function startThrottle(type, time, store) {

    removeThrottle(type)

    let timeout = setTimeout(() => { start(type, store)  }, time);

    throttleTimeouts.push({type: type, timeout: timeout})

}

function removeThrottle(type) {

    const throttle = throttleTimeouts.find(throttle => throttle.type === type)

    if(throttle) {

        clearTimeout(throttle.timeout)

        const index = throttleTimeouts.indexOf(throttle)
        let removed = throttleTimeouts.splice(index, 1)
    }

}
