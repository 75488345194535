export default function ({store, $bus, $ziggy}) {
    window.onNuxtReady(() => addEvents(store, $bus, $ziggy).catch(console.error))
}

async function addEvents(store, $bus, $ziggy) {

    store.subscribe(mutation => {
        if (mutation.type === 'search/ADD_To_Favorites') {
            store.$axios.post($ziggy.route('api.search.favorite.add', undefined, false), {company: mutation.payload})
        }
    })

    store.subscribe(mutation => {
        if (mutation.type === 'search/REMOVE_Item_From_Favorites') {
            store.$axios.post($ziggy.route('api.search.favorite.del', undefined, false), {company: mutation.payload})
        }
    })

    store.subscribe(mutation => {
        if (mutation.type === 'search/CLEAR_ALL_Favorites') {
            store.$axios.post($ziggy.route('api.search.favorite.clear', undefined, false), {company: mutation.payload})
        }
    })


    store.subscribe(mutation => {
        if (mutation.type === 'search/ADD_Item') {
            store.$axios.post($ziggy.route('api.search.rfp.add', undefined, false),
                {
                    company: mutation.payload,
                    list: store.getters['search/requestCartDestinationIds']
                })
        }
    })

    store.subscribe(mutation => {
        if (mutation.type === 'search/REMOVE_Item') {
            store.$axios.post($ziggy.route('api.search.rfp.del', undefined, false),
                {
                    company: mutation.payload,
                    list: store.getters['search/requestCartDestinationIds']
                })
        }
    })

    store.subscribe(mutation => {
        if (mutation.type === 'search/ADD_ALL_To_Request') {
            store.$axios.post($ziggy.route('api.search.rfp.add.all', undefined, false),
                {
                    company: mutation.payload,
                    list: store.getters['search/requestCartDestinationIds']
                })
        }
    })

    store.subscribe(mutation => {
        if (mutation.type === 'search/CLEAR_ALL_Requests') {
            store.$axios.post($ziggy.route('api.search.rfp.clear', undefined, false), {company: mutation.payload})
        }
    })


    $bus.$on('search/addFavorite', payload => {
        store.commit('search/ADD_To_Favorites_Sync', payload)
    })

    $bus.$on('search/delFavorite', payload => {
        store.commit('search/REMOVE_Item_From_Favorites_Sync', payload)
    })

    $bus.$on('search/clearFavorites', payload => {
        store.commit('search/CLEAR_ALL_Favorites_Sync')
    })


    $bus.$on('search/addRequest', payload => {
        store.commit('search/ADD_Item_Sync', payload)
    })

    $bus.$on('search/delRequest', payload => {
        store.commit('search/REMOVE_Item_Sync', payload)
    })

    $bus.$on('search/addAllToRequest', payload => {
        store.commit('search/ADD_ALL_To_Request_Sync', payload)
    })

    $bus.$on('search/clearRequests', payload => {
        store.commit('search/CLEAR_ALL_Requests_Sync', payload)
    })

}
