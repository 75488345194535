/*import createEchoSyncPlugin from '~/plugins/vuex/echo-sync'

const echoPlugin = createEchoSyncPlugin()

export const plugins = [echoPlugin]*/

//export const strict = false

export const state = () => ({
    user: false,
    onProfile: false,
    showNavSearch: false,
    wrapperClass: false,
    statusReload: 'disabled',
    echoToken: false,
    metaReady: false,
    trackPending: false,
    htmlClass: false,
    hideFooter: false,
    menuIsActive: false,
    fontsLoaded: false,
    isCrawler: false,
    navigation: [],
    footer: [],
    sentryLoaded: false,
    hideFooter: false,
    features: false,
})

export const mutations = {
    setHideFooter (state, value) {
        state.hideFooter = value
    },
    setOnProfile (state, value) {
        state.onProfile = value
    },
    setSentryLoaded (state, value) {
        state.sentryLoaded = value
    },
    setNavigation (state, value) {
        state.navigation = value
    },
    setFooter (state, value) {
        state.footer = value
    },
    setIsCrawler (state, value) {
        state.isCrawler = value
    },
    setFontsLoaded (state, value) {
        state.fontsLoaded = value
    },
    toggleMenuState (state) {
        state.menuIsActive = !state.menuIsActive
    },
    setShowNavSearch (state, value) {
        state.showNavSearch = value
    },
    setWrapperClass (state, value) {
        state.wrapperClass = value
    },
    setFeatures (state, value) {
        state.features = value
    },
}

export const actions = {

    async nuxtServerInit({commit}, { $xhrCache, req }) {

        const navigation = await $xhrCache.getResourceById('navigation')
        commit('setNavigation', navigation)

        const footer = await $xhrCache.getResourceById('footer')
        commit('setFooter', footer)

        const features = await $xhrCache.getResourceById('features')
        commit('setFeatures', features)

        //console.log('req', req)
    }
}
