export const state = () => ({
    waiters: [],
})

export const mutations = {
    ADD: (state, type) => {
        const found = state.waiters.find(waiter => waiter === type)

        if(found) {
            return false
        }

        return state.waiters.push(type)
    },
    REMOVE: (state, type) => {
        const found = state.waiters.find(waiter => waiter === type)

        if(!found) {
            return false
        }

        const index = state.waiters.indexOf(found)
        if (index > -1) {
            state.waiters.splice(index, 1);
        }
    }
}

export const getters = {
    has: (state) => (type) => {
        const found = state.waiters.find(waiter => waiter === type)
        if(found) {
            return true
        }
        return false
    }
}

export const actions = {
    start({ commit }, type) {
        commit("ADD", type);
    },
    end({ commit }, type) {
        commit("REMOVE", type);
    },
}
