
function getParams(type, payload) {

    const params = {

        country: {
            country: payload.term,
            slug: payload.slug,
            page: payload.page,
            filter: payload.filter,
            seo: payload.seo,
        },

        city: {
            city: payload.term,
            country: payload.country,
            slug: payload.slug,
            page: payload.page,
            filter: payload.filter,
        },

        name: {
            q: payload.term,
            page: payload.page,
            filter: payload.filter,
        }
    }

    return params[type]
}

export default {

    search: {

        country: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.country'), {
                params: getParams('country', payload)
            })

        },

        city: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.city'), {
                params: getParams('city', payload)
            })

        },

        name: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.name'), {
                params: getParams('name', payload)
            })

        },

    },

    featured: {

        country: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.country.featured'), {
                params: getParams('country', payload)
            })

        },

        city: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.city.featured'), {
                params: getParams('city', payload)
            })

        },

    },

    marker: {

        country: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.country.marker'), {
                params: getParams('country', payload)
            })

        },

        city: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.city.marker'), {
                params: getParams('city', payload)
            })

        },

        name: function (payload, $axios, $ziggy) {

            return $axios.$get($ziggy.route('api.search.name.marker'), {
                params: getParams('name', payload)
            })

        },

    },

}
