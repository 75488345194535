export default  {

    lazy: {
        elements_selector: '.v-lazy',
        use_native: false,
        threshold: 150,
        class_applied: 'v-lazy-applied',
        class_loading: 'v-lazy-loading',
        class_loaded: 'v-lazy-loaded',
        class_error: 'v-lazy-error',
        class_entered: 'v-lazy-entered',
        class_exited: 'v-lazy-exited',
        callback_error: errorCallback
    },

    lazyBg: {
        elements_selector: '.v-lazy-bg',
        use_native: false,
        threshold: 150,
        class_applied: 'v-lazy-bg-applied',
        class_loading: 'v-lazy-bg-loading',
        class_loaded: 'v-lazy-bg-loaded',
        class_error: 'v-lazy-bg-error',
        class_entered: 'v-lazy-bg-entered',
        class_exited: 'v-lazy-bg-exited',
    }

}

function errorCallback(el) {
    //console.log("Error", el)

    if(el.hasAttribute('data-err')) {

        const dataErr = el.getAttribute('data-err')

        if(dataErr != '' && dataErr !== null) {
            el.src = dataErr
        }

    }



}
