
export default ({ app, $config, $bus, store }, inject) => {
    if(store.state.features['tracking_posthog'] === true) {
        const options = {
            host: $config.postHogHost,
            token: $config.postHogToken,
        }

        const PostHog = new PostHogManager(app.router, options, $bus, store)

        inject('PostHog', PostHog)
    }
}

class PostHogManager {
    constructor(router, options, bus, store) {
        this.options = options
        this.router = router
        this.bus = bus
        this.store = store
    }

    async start(auth) {
        this.auth = auth
        this.posthog = (await import('posthog-js' /* webpackChunkName: 'ph' */)).default

        this.initPostHog(this.options)
        this.initEvents()

        if(this.store.state.tracking.trackInitialPageView === true) {
            this.posthog.capture('$pageview')
        }

        this.bus.$on('track/run', payload => {
            //if(!payload.isInitial || payload.isInitial !== true) {
                const to = payload.to

                this.posthog.capture('$pageview', {
                    $current_url: to.fullPath
                })
            //}
        })
    }

    getSentryPlugin() {
        return new posthog.SentryIntegration(posthog, 'https://sentry-new.dev-factory.de/organizations/evintra/projects/', 15)
    }

    initPostHog(options) {
        this.posthog.init(options.token, {
            api_host: options.host,
            debug: options.debug,
            capture_pageview: false,
            secure_cookie: true,
            rageclick: true,
            loaded: posthog => {
                this.bus.$emit('posthog/loaded')

                if(this.auth.loggedIn) {
                    const user = this.auth.user
                    const properties = this.getUserProperties(user)
                    posthog.identify(user.id, properties)
                }
            }
        })
    }

    getUserProperties(user) {
        return {
            email: user.email,
            type: user.type,
            verified: user.verified == 1 ? true : false,
            registrationComplete: user.registrationComplete
        }
    }

    onLogin(user) {
        if (!this.posthog.get_property('$user_id')) {
            const properties = this.getUserProperties(user)

            this.posthog.identify(user.id, properties)
        }
    }

    onLogout() {
        if (this.posthog.get_property('$user_id')) {
            this.posthog.reset()
        }
    }

    initEvents()
    {
        this.bus.$on('auth/login', this.onLogin.bind(this))
        this.bus.$on('auth/logout', this.onLogout.bind(this))

        this.bus.$on('provider/search', this.onProviderSearch.bind(this))
        this.bus.$on('posthog/event', this.onGenericEvent.bind(this))
        this.bus.$on('faq/search', this.onFaqSearch.bind(this))
        this.bus.$on('products/search', this.onProductsSearch.bind(this))
    }

    onProviderSearch(params)
    {
        this.posthog.capture('[provider-search]', params)
    }

    onGenericEvent(params)
    {
        this.posthog.capture('['+ params.name +']', params.properties)
    }

    onFaqSearch(query)
    {
        this.posthog.capture('[faq-search]', {query: query})
    }

    onProductsSearch(params)
    {
        this.posthog.capture('[products-search]', params)
    }
}
