
export default ({ app, $config }, inject) => {

    function head() {
        return new Head(app.router, $config)
    }

    inject('head', head)
}


class Head {

    constructor(router, config) {

        this.router = router
        this.config = config
        this.page = {}
        this.bodyAttrs = false
        this.canonicalUrl = null
        this.noCanonical = false
        this.metaNoIndex = false
        this.links = []
    }

    get() {

        let data = {
            title: this.title(),
            meta: this.meta(),
            link: this.canonical(),
            __dangerouslyDisableSanitizers: ['title']
        }

        if(this.bodyAttrs) {

            if(Array.isArray(this.bodyAttrs)) {
                data.bodyAttrs = {
                    class: this.bodyAttrs
                }
            } else {
                data.bodyAttrs = {
                    class: [this.bodyAttrs]
                }
            }

        }

        if(this.metaNoIndex) {

            data.meta.push({
                hid: 'robots',
                name: 'robots',
                content: 'noindex'
            })

        }

        if(this.links.length) {
            this.links.forEach(link => {
                data.link.push(link)
            })
        }

        return data
    }

    title() {
        return this.page.title
    }

    canonical() {

        if(this.metaNoIndex === true) {
            return []
        }

        if(this.buildCanonicalUrl() === false) {
            return []
        }

        return [
            {
                hid: 'canonical',
                rel: 'canonical',
                href: this.buildCanonicalUrl()
            }
        ]
    }

    meta() {
        return [
            {
                hid: 'description',
                name: 'description',
                content: this.page.description
            },
            {
                hid: 'twitter:url',
                name: 'twitter:url',
                content: this.fullUrl()
            },
            {
                hid: 'twitter:title',
                name: 'twitter:title',
                content: this.page.title
            },
            {
                hid: 'twitter:description',
                name: 'twitter:description',
                content: this.page.description
            },
            {
                hid: 'twitter:image',
                name: 'twitter:image',
                content: this.page.shareImage
            },
            {
                hid: 'twitter:image:alt',
                name: 'twitter:image:alt',
                content: this.page.title
            },
            {
                hid: 'og:title',
                property: 'og:title',
                content: this.page.title
            },
            {
                hid: 'og:type',
                property: 'og:type',
                content: this.page.type
            },
            {
                hid: 'og:description',
                property: 'og:description',
                content: this.page.description
            },
            {
                hid: 'og:url',
                property: 'og:url',
                content: this.fullUrl()
            },
            {
                hid: 'og:image',
                property: 'og:image',
                content: this.page.shareImage
            },
            {
                hid: 'og:image:secure_url',
                property: 'og:image:secure_url',
                content: this.page.shareImage
            },
            {
                hid: 'og:image:alt',
                property: 'og:image:alt',
                content: this.page.title
            }
        ]
    }


    setPage(page) {
        this.page = page
        return this
    }

    setBodyAttrs(attrs) {
        this.bodyAttrs = attrs
        return this
    }

    setCanonicalUrl(url) {
        this.canonicalUrl = url
        return this
    }

    disableCanonical() {
        this.noCanonical = true
        return this
    }

    noindex() {
        this.metaNoIndex = true
        return this
    }

    addLink(link) {
        this.links.push(link)
        return this
    }

    addLinks(links) {

        if(!links.length) {
            return this
        }

        links.forEach(link => {
            this.links.push(link)
        })

        return this
    }

    buildCanonicalUrl() {

        if(this.noCanonical === true) {
            return false
        }

        if(this.canonicalUrl !== null) {
            return this.canonicalUrl
        }

        let path = this.path()

        if(path === '/') {
            path = ''
        }

        return this.config.baseURL + path
    }

    path() {
        return this.router.currentRoute.path
    }

    fullPath() {
        return this.router.currentRoute.fullPath
    }

    fullUrl() {

        let path = this.fullPath()

        if(path === '/') {
            path = ''
        }

        return this.config.baseURL + path
    }

}
