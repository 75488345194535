
export default function({ $bus, store }) {

    registerEvents($bus, store).catch(console.error)

}

async function registerEvents($bus, store) {

    window.onload = () => {

        if(store.state.ccm.consent === true) {
            addIdleCallback()
        } else {

            $bus.$on('ccm/consent', () => {
                addIdleCallback()
            })

        }

    }

}

function addIdleCallback() {

    if ('requestIdleCallback' in window) {

        window.requestIdleCallback(() => {
            const event = document.createEvent('Event');
            event.initEvent('nuxtIdle', true, true);
            window.dispatchEvent(event)
        })

    }

}
