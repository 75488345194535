//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


/*    import { helper } from '../../mixins/helper'
    import VueTinyLazyloadBg from '../vue-tiny-lazyload-bg/index'
    Vue.use(VueTinyLazyloadBg);*/

    import { helper } from '~/mixins/helper'
import FooterLinkGroup from "@/components/footer/FooterLinkGroup";

    export default {

        name: 'Footer',
        components: {FooterLinkGroup},
        mixins: [helper],

        data() {
            return {
                showSocial: false
            }
        },

        methods: {

            visibilityChangedLogobox (isVisible, entry) {

                if(isVisible) {
                    this.showSocial = true
                }

            }

        },

        computed: {

            footer: function () {
                return this.$store.state.footer
            },

        }
    }

