import { render, staticRenderFns } from "./default.vue?vue&type=template&id=2933e9a4&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopAlert: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/TopAlert.vue').default,NavigationCrawler: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/navigation/NavigationCrawler.vue').default,Navigation: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/navigation/Navigation.vue').default,LoadingPage: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/LoadingPage.vue').default,Footer: require('/home/gitlab-runner/builds/ra8PUEcp/0/evintra/nuxt/components/footer/Footer.vue').default})
