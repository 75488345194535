import Vue from 'vue'
import route from 'ziggy-js'
import Ziggy from '~/assets/js/ziggy/routes.json'

const ziggy = {
    route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config),
}

Vue.mixin({
    methods: {
        route: ziggy.route
        //route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config),
    },
});

export default (context, inject) => {
    inject('ziggy', ziggy)
}
