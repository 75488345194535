//
//
//
//
//
//
//
//


export default {

    name: 'LoadingPage',

    props: {
        loaderClass: {
            type: String,
            default: 'v-loading-block'
        },
        triggerScroll: {
            type: Boolean,
            default: false
        },
        scrollTop: {
            type: Boolean,
            default: false
        }
    },

/*    watch: {

        isWaitingPage: function (newVal, oldVal) {

            if(newVal === false) {

            }

        },

    },*/

    created() {

        if(process.client) {

            if(this.triggerScroll) {
                this.$nuxt.$nextTick(() => this.$nuxt.$emit('triggerScroll'))
            }

            if(this.scrollTop) {
                window.scrollTo(0, 0)
            }

        }
    },

    activated() {

        if(process.client) {

            if(this.triggerScroll) {
                this.$nuxt.$nextTick(() => this.$nuxt.$emit('triggerScroll'))
            }

            if(this.scrollTop) {
                window.scrollTo(0, 0)
            }
        }

    },

    computed: {

        isWaitingPage: function() {

            if(process.server) {
                return false
            }

            return this.$waiter.has('page')
        },

    }
}

