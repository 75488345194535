//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    name: 'MeetingLayout',

    watch: {

        matomo(val) {
            this.$matomo.setConsent(val)
        },

        posthog(val) {
            if(this.$PostHog) {
                this.$PostHog.start(this.$auth)
            }
        }

    },


    head: {
        afterNavigation(metaInfo) {
            $nuxt.$bus.$emit('afterNavigation', metaInfo)
        },
        changed(newInfo, addedTags, removedTags) {
            $nuxt.$bus.$emit('headChanged', newInfo)
        }
    },

    created() {
        this.$waiter.start('jitsi-loading')
    },

    computed: {

        matomo() {
            return this.$store.state.ccm.matomo
        },
        posthog() {
            return this.$store.state.ccm.posthog
        },
        fontsLoaded: function () {
            return this.$store.state.fontsLoaded
        },
        isCrawler() {
            return this.$store.state.isCrawler
        },
        isLoading: function() {
            return this.$waiter.has('jitsi-loading')
        },
    },
}
