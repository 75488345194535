//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {helper} from "@/mixins/helper"

export default {

    name: 'FooterLinkGroup',

    props: ['group', 'groupName'],

    mixins: [helper],

    methods: {

        navigationAllowed(item) {

            if(item.onlyGuest === true && this.authed) {
                return false
            }

            if(item.onlyAuth === true && this.authed === false) {
                return false
            }

            if(item.onlyProviderComplete === true) {
                return this.userRegCompleted && this.userHasCompanyProfile && this.userIs('provider')
            }

            return true

        },

        getRoute(item) {

            if(item.routeParams) {
                return this.route(item.route, item.routeParams)
            } else {
                return this.route(item.route)
            }

        }

    }

}

