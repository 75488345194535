import manifest from '~/assets/js/manifest/mix-manifest.json'

function mix(path) {

    if (typeof manifest[path] !== undefined) {
        return manifest[path]
    }

    return path;
}

export default function (context, inject) {
    inject('mix', mix)
}
