export const state = () => ({
    category: false,
    scrollToCategories: false,
})

export const mutations = {
    category(state, category) {
        state.category = category
    },
    scrollToCategories(state, value) {
        state.scrollToCategories = value
    }
}
