
export default async (context, inject) => {

    const helper = {
        getResourceById: (resourceId, ctx = context) => getResourceById(resourceId, ctx)
    }

    inject('xhrHelper', helper)

}

const delay = t => new Promise(resolve => setTimeout(resolve, t));

async function getResourceById(resourceId, context) {

    const { xhrCache, error, $axios } = context

    return new Promise((resolve, reject) => {

        _getResourceById(resourceId, xhrCache)
            .then(function (resource) {

                if(!resource || resource === null || resource === '') {

                    refreshResourceById(resourceId, $axios)
                        .then(function (response) {

                            delay(3000).then(() => {

                                _getResourceById(resourceId, xhrCache)
                                    .then(function (resource) {
                                        resolve(resource)
                                    })
                                    .catch(function (error) {
                                        reject(error)
                                    })

                            });

                        })

                } else {
                    resolve(resource)
                }

            })
            .catch(function (error) {
                reject(error)
            });

    })


}

async function _getResourceById(resourceId, xhrCache) {

    try{
        const resource = await xhrCache.getResourceById(resourceId)
        return resource

    } catch (e) {
        throw(e)
    }

}

async function refreshResourceById(resourceId, $axios) {

    const response = await $axios.$get(`/api/xhr/refresh/${resourceId}`)
    return response
}
