export const state = () => ({
    initialized: false,
    navigation: [],
    navCollapsed: false,
    translateMenuPhone: false,
    logo: false,
    user: [],
    isGmcsSurvey: false,
    publish: false,
})

export const mutations = {
    setPublish (state, value) {
        state.publish = value
    },
    setInitialized (state, value) {
        state.initialized = value
    },
    setNavigation (state, value) {
        state.navigation = value
    },
    setLogo (state, value) {
        state.logo = value
    },
    setNavCollapsed (state, value) {
        state.navCollapsed = value
    },
    setTranslateMenuPhone (state, value) {
        state.translateMenuPhone = value
    },
    setUser (state, value) {
        state.user = Object.assign({}, state.user, value)
    },
    setIsGmcsSurvey (state, value) {
        state.isGmcsSurvey = value
    },
}

export const actions = {

    GET_NAVIGATION ({ commit, state }) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.provider.navigation'))
                .then((response) => {

                    commit('setNavigation', response.nav)
                    commit('setLogo', response.logo)
                    commit('setUser', { gmcs: response.gmcs })

                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

}
