//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import GoogleAutocompleteMixin from '~/components/google/mixins/GoogleAutocompleteMixin'

export default {

    name: 'GoogleAutocomplete',

    mixins: [GoogleAutocompleteMixin],

}

