//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { autocomplete } from '~/mixins/autocomplete'

export default {

    mixins: [autocomplete],

    data() {
        return {
            loadApi: false,
            isMobileSearch: true,
        }
    },

    methods: {

        focus() {
            this.$refs['autocomplete-nav-small'].focus()
        }

    },

}

