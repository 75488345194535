
export default function({ app, store }) {

    const router = app.router

    handleStates(router, store).catch(console.error)

}

async function handleStates(router, store) {

    router.beforeEach((to, from, next) => {

        //console.log('beforeEach', to.meta.onProfile, from.meta.onProfile)

        if(!to.meta.onProfile) {
            store.commit('setOnProfile', false)
        } else {
            store.commit('setOnProfile', to.meta.onProfile)
        }

        let wrapperClasses = [];

        if(!to.meta.noWrapperClass) {
            wrapperClasses.push('wrapper')
        }

        if(to.meta.wrapperClass) {
            wrapperClasses.push(to.meta.wrapperClass)
        }

        store.commit('setWrapperClass', wrapperClasses)

        if(!to.meta.showNavSearch) {
            store.commit('setShowNavSearch', false)
        } else {
            store.commit('setShowNavSearch', to.meta.showNavSearch)
        }

        if(!to.meta.hideFooter) {
            store.commit('setHideFooter', false)
        } else {
            store.commit('setHideFooter', to.meta.hideFooter)
        }

        next()
    })

}
