import api from '~/store-api/search'

export const state = () => ({
    companies: [],
    featuredMember: false,
    resultCount: false,
    searchTermDisplay: false,
    meta: false,
    links: false,
    hostedBuyer: false,
    sponsored: false,
    services: false,
    categories: [
        {
            name: "All",
            path: ""

        },
        {
            name: "DMC",
            path: "dmc"

        },
        {
            name: "Event Agency",
            path: "event agency"

        },
        {
            name: "Supplier",
            path: "supplier"

        },
        {
            name: "Venue",
            path: "venue"

        },
        {
            name: "Hotel",
            path: "hotel"

        },
        {
            name: "Travel",
            path: "travel"

        },
        {
            name: "Tourism/Convention bureau",
            path: "tourism convention bureau"

        },
    ],

    requestCart: [],
    sidebarCart: {},
    favoriteCart: [],
    selectedCategory: "All",
    isOpen: false,
    currentComponent: '',
    latitude: false,
    longitude: false,
    marker: false,
    searchPayload: false,
})

export const getters = {
    services: (state) => state.services,
    hostedBuyer: (state) => state.hostedBuyer,
    sponsored: (state) => state.sponsored,
    featuredMember: (state) => state.featuredMember,
    searchPayload: (state) => state.searchPayload,
    marker: (state) => state.marker,
    longitude: (state) => state.longitude,
    latitude: (state) => state.latitude,
    meta: (state) => state.meta,
    links: (state) => state.links,
    resultCount: (state) => state.resultCount,
    searchTermDisplay: (state) => state.searchTermDisplay,
    categories: (state) => state.categories,
    sidebarCart: (state) => state.sidebarCart,
    favoriteCart: (state) => state.favoriteCart,
    requestCart: (state) => state.requestCart,
    requestCartDestinationIds: (state) => {
        let list = []
        state.requestCart.forEach(entry => {
            list.push(entry.destination_id)
        })
        return list
    },

    companies: (state) => {

        if(Array.isArray(state.companies) === false) {
            return []
        }

        var companies = state.companies.map(company => {

            const newCompany = JSON.parse(JSON.stringify(company));

            const favorite = state.favoriteCart.find(fav => fav.id === company.id)

            if(favorite) {
                newCompany.isLiked = true
            }

            return newCompany

        })
        return companies
    },

    keyfacts: (state) => {
        return state.sidebarCart.keyfacts.filter(fact => {
            return fact.value != null
        })
    },
    servicesType: (state) => (type) => {
        //return state.services.find(service => service.service_type === type)
        return state.services.filter(service => {
            return service.service_type === type
        })
    },
    servicesTypeOld: (state, type) => {
        return state.services.filter(service => {
            return service.service_type === type
        })
    },

}

export const mutations = {
    services: (state, services) => state.services = services,
    hostedBuyer: (state, hostedBuyer) => state.hostedBuyer = hostedBuyer,
    sponsored: (state, sponsored) => state.sponsored = sponsored,
    featuredMember: (state, featuredMember) => state.featuredMember = featuredMember,
    searchPayload: (state, searchPayload) => state.searchPayload = searchPayload,
    latitude: (state, latitude) => state.latitude = latitude,
    longitude: (state, longitude) => state.longitude = longitude,
    marker: (state, marker) => state.marker = marker,
    meta: (state, meta) => state.meta = meta,
    links: (state, links) => state.links = links,
    resultCount: (state, resultCount) => state.resultCount = resultCount,
    searchTermDisplay: (state, searchTermDisplay) => state.searchTermDisplay = searchTermDisplay,
    selectedCategory: (state, selectedCategory) => state.selectedCategory = selectedCategory,
    isLoading: (state, isLoading) => state.isLoading = isLoading,
    isOpen: (state, isOpen) => state.isOpen = isOpen,
    currentComponent: (state, currentComponent) => state.currentComponent = currentComponent,
    // Add item to request card
    ADD_Item(state, company) {
        const found = state.requestCart.find(cart => cart.id === company.id)
        // check if item not exist to add it to the card
        if (!found) {
            return state.requestCart.push(company);
            this.$toast.global.my_added({message: 'Request added'})
        } else {
            return false
        }
    },
    ADD_Item_Sync(state, company) {
        const found = state.requestCart.find(cart => cart.id === company.id)
        // check if item not exist to add it to the card
        if (!found) {
            return state.requestCart.push(company);
        } else {
            return false
        }
    },
    // Add item To favorite card
    ADD_To_Favorites(state, company) {
        const found = state.favoriteCart.find(cart => cart.id === company.id)
        if (!found) {
            return state.favoriteCart.push(company);
        } else {
            return false
        }
    },
    ADD_To_Favorites_Sync(state, company) {
        const found = state.favoriteCart.find(cart => cart.id === company.id)
        if (!found) {
            return state.favoriteCart.push(company);
        } else {
            return false
        }
    },
    SET_COMPANY_Favorite(state, companyId) {
        var found = state.companies.find(company => company.id === companyId)
        if(found) {
            found.isLike = true
        }
    },
    // Add All item to request cart
    ADD_ALL_To_Request(state) {

        const maxPerRequest = this.$config.rfp.maxPerRequest

        const result = state.favoriteCart.every(company => {

            var found = state.requestCart.find(cart => cart.id === company.id)

            if (!found) {

                if(state.requestCart.length < maxPerRequest) {
                    state.requestCart.push( company )
                } else {
                    const message = 'Max allowed entries per request reached:: ' + this.$config.rfp.maxPerRequest
                    this.$toast.global.my_error({message: message})

                    return false
                }

            }

            return true

        })

        if(result) {
            this.$toast.global.my_added({message: 'Requests added'})
        }

    },
    ADD_ALL_To_Request_Sync(state) {
        // eslint-disable-next-line no-unused-vars

        return state.favoriteCart.forEach(company => {

            var found = state.requestCart.find(cart => cart.id === company.id)

            if (!found) {
                return state.requestCart.push( company )
            }

        })
    },
    // Add overview of company  To Sidebar when click on MORE INFO button
    ADD_To_Sidebar(state, company) {
        return state.sidebarCart = company;
    },
    // Clear Sidebar
    ClEAR_Sidebar(state) {
        state.sidebarCart = {}
        state.latitude = false
        state.longitude = false
        state.marker = false
    },
    // Remove item from request card
    REMOVE_Item(state, index) {
        state.requestCart.splice(index, 1);
    },
    REMOVE_Item_Sync(state, index) {
        state.requestCart.splice(index, 1);
    },

    // Remove item from favorite card
    REMOVE_Item_From_Favorites(state, company) {

        const favorite = state.favoriteCart.find(fav => fav.id === company.id)

        if(favorite) {

            const index = state.favoriteCart.indexOf(favorite)
            if (index > -1) {
                state.favoriteCart.splice(index, 1);
            }

        }

    },

    REMOVE_Item_From_Favorites_Sync(state, company) {

        const favorite = state.favoriteCart.find(fav => fav.id === company.id)

        if(favorite) {

            const index = state.favoriteCart.indexOf(favorite)
            if (index > -1) {
                state.favoriteCart.splice(index, 1);
            }

        }

    },
    // Clear all items from favorite card
    CLEAR_ALL_Favorites(state) {
        state.favoriteCart = [];
        state.companies.forEach(company => {
            if (company.isLiked === true) {
                company.isLiked = false
            }
        })
    },
    CLEAR_ALL_Favorites_Sync(state) {
        state.favoriteCart = [];
        state.companies.forEach(company => {
            if (company.isLiked === true) {
                company.isLiked = false
            }
        })
    },
    // Clear all items from Requests card
    CLEAR_ALL_Requests(state) {
        state.requestCart = [];
    },
    CLEAR_ALL_Requests_Sync(state) {
        state.requestCart = [];
    },


    ADD_Companies(state, companies) {
        state.companies = companies
    },

    MERGE_Companies(state, companies) {
        state.companies.push(...companies)
    },

}

export const actions = {

    // Add item to request card
    addItem({ commit, state }, company) {

        const maxPerRequest = this.$config.rfp.maxPerRequest

        if(state.requestCart.length < maxPerRequest) {
            commit("ADD_Item", company);
            this.$toast.global.my_added({message: 'Request added'})
        } else {
            const message = 'Max allowed entries per request reached:: ' + this.$config.rfp.maxPerRequest
            this.$toast.global.my_error({message: message})

        }

    },
    // Add All items to request card
    addAllToRequest({ commit }) {
        commit("ADD_ALL_To_Request");
    },
    // Add items to sidebar
    AddItemToSidebar(context, company) {
        // clear sidebar before open it
        context.commit("ClEAR_Sidebar");
        context.commit("ADD_To_Sidebar", company);
    },
    // Add item to Favorites card
    addToFavorites({ state, commit}, company) {

        const favMax = this.$config.favMax

        if(state.favoriteCart.length < favMax) {
            commit("ADD_To_Favorites", company);
            this.$toast.global.my_added({message: 'Favorite added'})
        } else {
            const message = 'Max allowed favorites reached:: ' + this.$config.faxMax
            this.$toast.global.my_error({message: message})
        }
    },
    // remove item from request card
    removeItem(context, index) {
        context.commit("REMOVE_Item", index);
        this.$toast.global.my_removed({message: 'Request removed'})
    },
    // remove item from Favorites card
    removeFromFavorites({ commit }, company) {
        commit("REMOVE_Item_From_Favorites", company);
        this.$toast.global.my_removed({message: 'Favorite removed'})
    },
    // Clear All item from Favorites card
    ClearAllFavorites(context) {
        context.commit("CLEAR_ALL_Favorites");
        this.$toast.global.my_removed({message: 'Favorites cleared'})
    },
    // Clear All item from Requests card
    ClearAllRequests(context) {
        context.commit("CLEAR_ALL_Requests");
        this.$toast.global.my_removed({message: 'Requests cleared'})
    },
    // Clear Sidebar
    clearSidebar(context) {
        context.commit("ClEAR_Sidebar");
    },

    doSearch(context, payload) {

        return new Promise((resolve, reject) => {

            context.dispatch('waiter/start', 'search.do', { root: true });

            context.commit('resultCount', false)
            context.commit('searchTermDisplay', false)
            context.commit('searchPayload', payload)

            api.search[payload.type](payload, this.$axios, this.$ziggy)
                .then((response) => {
                    context.commit('ADD_Companies', response.result)
                    context.commit('resultCount', response.meta.total)
                    context.commit('searchTermDisplay', response.meta.search_term_display)
                    context.commit('meta', response.meta)
                    context.commit('links', response.links)
                    resolve(response)
                })
                .catch((error) => {
                    context.commit('ADD_Companies', false)
                    context.commit('resultCount', false)
                    context.commit('searchTermDisplay', false)
                    context.commit('meta', false)
                    context.commit('links', false)
                    reject(error)
                })
                .then(function () {
                    context.dispatch('waiter/end', 'search.do', {root: true});
                })

        })

    },

    doLoadMore(context, payload) {

        return new Promise((resolve, reject) => {

            context.dispatch('waiter/start', 'search.load-more', { root: true });

            api.search[payload.type](payload, this.$axios, this.$ziggy)
                .then((response) => {

                    if (response.result.length) {
                        context.commit('MERGE_Companies', response.result)
                    }

                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
                .then(function () {
                    context.dispatch('waiter/end', 'search.load-more', {root: true});
                })

        })

    },

    doFeatured(context, payload) {

        return new Promise((resolve, reject) => {

            context.dispatch('waiter/start', 'search.featured', { root: true });

            api.featured[payload.type](payload, this.$axios, this.$ziggy)
                .then((response) => {
                    context.commit('featuredMember', response.result)
                    resolve(response)
                })
                .catch((error) => {
                    context.commit('featuredMember', false)
                    reject(error)
                })
                .then(function () {
                    context.dispatch('waiter/end', 'search.featured', {root: true});
                })

        })

    },

    doMarker(context, payload) {

        return new Promise((resolve, reject) => {

            context.dispatch('waiter/start', 'search.marker', { root: true });

            api.marker[payload.type](payload, this.$axios, this.$ziggy)
                .then((response) => {
                    context.commit('marker', response.locations)
                    context.commit('latitude', response.latitude)
                    context.commit('longitude', response.longitude)
                    resolve(response)
                })
                .catch((error) => {
                    context.commit('marker', false)
                    reject(error)
                })
                .then(function () {
                    context.dispatch('waiter/end', 'search.marker', { root: true });
                })

        })

    },

    doHostedBuyer(context) {
        context.dispatch('waiter/start', 'search.hostedbuyer', { root: true });

        this.$axios.$get(this.$ziggy.route('api.search.hostedbuyer'))
            .then((response) => {
                context.commit('hostedBuyer', response.result)
            })
            .catch((error) => {
                context.commit('hostedBuyer', false)
            })
            .then(function () {
                context.dispatch('waiter/end', 'search.hostedbuyer', { root: true });
            })
    },

    doSponsored(context) {
        context.dispatch('waiter/start', 'search.sponsored', { root: true });

        this.$axios.$get(this.$ziggy.route('api.search.sponsored'))
            .then((response) => {
                context.commit('sponsored', response.data)
            })
            .catch((error) => {
                context.commit('sponsored', false)
            })
            .then(function () {
                context.dispatch('waiter/end', 'search.sponsored', { root: true });
            })
    },

    doServices(context) {

        return new Promise((resolve, reject) => {

            context.dispatch('waiter/start', 'search.services', { root: true });

            this.$axios.$get(this.$ziggy.route('api.search.services'))
                .then((response) => {
                    context.commit('services', response.result)
                    resolve(response)
                })
                .catch((error) => {
                    context.commit('services', false)
                    reject(error)
                })
                .then(function () {
                    context.dispatch('waiter/end', 'search.services', { root: true });
                })

        })

    },
}
