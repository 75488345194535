//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { helper } from '~/mixins/helper'

import { BNavItemDropdown, BDropdownItem, VBHover } from 'bootstrap-vue'

import Vue from 'vue'
Vue.directive('b-hover', VBHover)

export default {

    name: 'Navigation',

    props: {
        onErrorPage: {
            type: Boolean,
            default: false
        }
    },

    components: {
        BNavItemDropdown,
        BDropdownItem,
    },

/*    components: {
        NavSearch, NavSearchSmall,
        'Authed': () => import('../navigation/Authed'),
    },*/

    mixins: [helper],

    data() {
        return {
            showDefault: true,
            featureAcademy: true,
            windowWidth:  0,
            windowHeight: 0,
            showMobile: false,
            menuHovered: false,
        }
    },

    /*watch: {
        '$route'() {
            if(this.showMobile) {
                this.closeMobile()
            }
        }
    },*/

    created() {
        this.$bus.$on('navigation/hide', this.hideNavigation)
    },

    destroyed() {
        this.$bus.$off('navigation/hide', this.hideNavigation)
    },

    mounted() {

        if(this.isCrawler === false) {

            this.$nextTick(function () {
                this.onResize()
            })

        }

    },

    methods: {

        async userLogout() {
            try {
                this.$waiter.start('page')
                this.$toast.global.my_loading({message: 'Logging out...'})
                await this.$auth.logout()
                this.$auth.redirect('logout')

            } catch (e) {
                this.$logger.captureException(e)
            }
        },

        navigationAllowed(navItem) {

            if(navItem.onlyGuest === true && this.authed) {
                return false
            }

            if(navItem.onlyAuth === true && this.authed === false) {
                return false
            }

            if(navItem.onlyProviderComplete === true) {
                return this.userRegCompleted && this.userHasCompanyProfile && this.userIs('provider')
            }

            if(navItem.featureFlag && this.features[navItem.featureFlag] !== true) {
                return false
            }

            return true

        },

        subNavigationAllowed(navItem) {

            if(navItem.featureFlag && this.features[navItem.featureFlag] !== true) {
                return false
            }

            return true

        },

        handleHover(hovered, e) {
            //console.log('hover', e, e.target.id)

            if(hovered) {
                this.menuHovered = e.target.id
            } else {
                this.menuHovered = false
            }

        },

        hideNavigation: function () {

            this.menuHovered = false

            if(this.showMobile) {
                this.closeMobile()
            }
        },


        onResize: function () {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight

        },

        toggleMobile: function () {

            this.showMobile = !this.showMobile

            this.checkWrapperOverlayHeight()

            if(this.showMobile === true) {
                this.$refs.navSearchSmall.focus()
            }

        },

        openMobile: function () {
            this.showMobile = true
            this.checkWrapperOverlayHeight()
        },

        closeMobile: function () {
            this.showMobile = false
            this.checkWrapperOverlayHeight()
        },

        checkWrapperOverlayHeight:  function () {

            let wrapper = this.$refs.navWrapperOverlay;

            if(this.showMobile === false) {
                wrapper.style.height = 0 + 'px'
            }

            wrapper.style.height = this.windowHeight + 'px'
        },

        isHovered(id) {

            if(this.menuHovered === false) {
                return false
            }

            return this.menuHovered === id

        },

        showAuthedNav(type) {

            if(this.userRegCompleted === false) {
                return false
            }

            if(this.userIs(type)) {
                return true;
            }

            return false;

        },

    },


    computed: {

        navigation: function () {
            return this.$store.state.navigation
        },

        dropdownHover: function () {

            if (this.windowWidth < 992 ) {
                return false
            }

            return true
        },

        showSearch: function () {
            return this.$store.state.showNavSearch
        },

        isCrawler() {
            return this.$store.state.isCrawler
        },

        isNavSearch() {

            if(this.onErrorPage) {
                return true
            }

            if(this.showSearch && !this.isCrawler) {
                return true
            }

            return false

        },

    }

}

