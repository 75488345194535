
export default function ({ redirect, $auth, $ziggy, $helper}) {

    const isAuthenticated = $auth.loggedIn

    if (!isAuthenticated) {
        return redirect({name: 'index'})
    }

    //console.log($auth.user.intendedUrl)

    if($auth.user.intendedUrl && $auth.user.intendedUrl !== null) {
      return redirect($auth.user.intendedUrl)
    }

    const target = $helper.userRedirectTo($auth.user, $ziggy)
    return redirect(target)

/*    const redirectRoute = $auth.user.loginRedirectRoute
    const redirectType = $auth.user.loginRedirectType

    if(redirectRoute && redirectRoute != '') {

        if(redirectType === 'nuxt') {
            return redirect({name: redirectRoute})
        } else {
            return redirect($ziggy.route(redirectRoute))
        }

    }

    return redirect({name: 'index'})*/
}
