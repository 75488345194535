//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { BAlert, BProgress, BIcon, BIconCheckCircleFill, BIconEnvelopeFill, BIconExclamationCircleFill } from 'bootstrap-vue'

export default {

    name: 'TopAlert',

    components: { BAlert, BProgress, BIcon, BIconCheckCircleFill, BIconEnvelopeFill, BIconExclamationCircleFill },

    data() {
        return {
            variant: 'success',
            message: '',
            icons: {
                check: 'check-circle-fill',
                sent: 'envelope-fill',
                error: 'exclamation-circle-fill'
            },
            icon: '',
            dismissSecs: 0,
            dismissCountDown: 0,
            showProgress: true
        }
    },

    created() {

        this.$bus.$on('alert/show', this.onAlertShow)

    },

    destroyed() {
        this.$bus.$off('alert/show', this.onAlertShow)
    },

    methods: {

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

        onAlertShow(message, dismissSecs = 5, icon = 'check', variant = 'success',  showProgress = false) {

            this.dismissCountDown = dismissSecs
            this.dismissSecs = dismissSecs

            this.showProgress = showProgress
            this.variant = variant

            this.message = message

            this.icon = this.icons[icon]

        }

    }

}

