//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import LazyHydrate from "vue-lazy-hydration";
import { BOverlay } from 'bootstrap-vue'

export default {

    name: 'DefaultLayout',

    components: { LazyHydrate, BOverlay },

    watch: {

        matomo(val) {
            this.$matomo.setConsent(val)
        },

        posthog(val) {
            if(this.$PostHog) {
                this.$PostHog.start(this.$auth)
            }
        }

    },

    data() {
        return {
        }
    },

    head: {
        afterNavigation(metaInfo) {
            //console.log('afterNavigation')
            $nuxt.$bus.$emit('afterNavigation', metaInfo)
        },
        changed(newInfo, addedTags, removedTags) {
            //console.log('headChanged layout', newInfo, addedTags, removedTags)
            $nuxt.$bus.$emit('headChanged', newInfo)
        }
    },

    created() {

        if (process.client) {

            //console.log('addEventListener nuxtIdle')

            window.addEventListener('nuxtIdle',e => {

                if(this.$googleApi.isLoaded() === false) {
                    this.$googleApi.load()
                }

            }, false)

        }

        this.$auth.$storage.watchState('loggedIn', loggedIn => {
            //console.log('watchState', loggedIn)

            if(loggedIn) {
                this.$sentry.setUser({ id: this.$auth.user.id })
            }

        })

    },

/*    mounted() {
        // This will only load sentry once an error was thrown
        // To prevent a chicken & egg issue, make sure to also
        // set injectMock: true if you use this so the error
        // that triggered the load will also be captured
        this.errorListener = () => {
            this.$sentryLoad()
            window.removeEventListener('error', this.errorListener)
        }
        window.addEventListener('error', this.errorListener)
    },

    destroyed() {
        window.removeEventListener('error', this.errorListener)
    },*/

    /*head() {
        return {
            /!*afterNavigation(metaInfo) {
               console.log('afterNavigation')
                //console.log($nuxt.$meta().getOptions())

            },*!/
/!*            changed (newInfo, addedTags, removedTags) {
                console.log('headChanged layout')
                $nuxt.$emit('headChanged', newInfo)
            }*!/
        }
    },*/

    /*mounted() {

        this.$echo.channel('appUpdate')
            .listen('.newRelease', (e) => {
                console.log(e);
            });
    },*/

    /*mounted() {

        console.log('mounted', window.__FONTS_LOADED__)

        this.$nuxt.$nextTick(() => {

            if(window.__FONTS_LOADED__ === true) {
                console.log('toggleFontsLoaded')
                this.$store.commit('toggleFontsLoaded')

                const { set, remove } = window.$nuxt.$meta().addApp('fonts')

                set({
                    htmlAttrs: {
                        class: ['fonts-loaded']
                    }
                })

            }

        })



    },*/

    computed: {

        matomo() {
            return this.$store.state.ccm.matomo
        },
        posthog() {
            return this.$store.state.ccm.posthog
        },
        wrapperClass: function () {
            return this.$store.state.wrapperClass
        },
        fontsLoaded: function () {
            return this.$store.state.fontsLoaded
        },
        isCrawler() {
            return this.$store.state.isCrawler
        },
        isWaitingPage: function() {
            return this.$waiter.has('page')
        },
        hideFooter: function () {
            return this.$store.state.hideFooter
        }
    },
}
