
export default async function ({ redirect, $auth, $ziggy, $axios }) {

    //$auth.reset()

    let response = await $auth.loginWith('impersonate', { data: { email: '', password: ''} })
    //console.log(response.data)
    $auth.setUser(response.data)

    return redirect('/user/login/successful')

/*    const isAuthenticated = $auth.loggedIn

    if (!isAuthenticated) {
        return redirect({name: 'index'})
    }

    const redirectRoute = $auth.user.loginRedirectRoute
    const redirectType = $auth.user.loginRedirectType

    if(redirectRoute && redirectRoute != '') {

        if(redirectType === 'nuxt') {
            return redirect({name: redirectRoute})
        } else {
            return redirect($ziggy.route(redirectRoute))
        }

    }

    return redirect({name: 'index'})*/
}
