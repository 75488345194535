import GoogleMapsApiLoader from 'google-maps-api-loader'

export default ({ $config, $logger, $bus }, inject) => {

    const googleApi = {

        loading: false,

        isLoaded() {

            if (!(typeof window.google === 'object' && window.google.maps)) {
                return false
            }

            return true
        },

        load() {

            if(this.loading) {
                return this.loading
            }

            this.loading = new Promise((resolve, reject) => {

                if(this.isLoaded()) {
                    return resolve()
                }

                GoogleMapsApiLoader({
                    libraries: ['places'],
                    apiKey: $config.googlePlacesApiKey,
                    language: 'en',
                })
                .then(function(googleApi) {
                    $bus.$emit('google-place-api/loaded')
                    resolve(googleApi)
                }, function(e) {
                    $logger.captureException(e)
                    reject(e)
                })

            })

            return this.loading

        }

    }

    inject('googleApi', googleApi)

}
