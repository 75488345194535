
export const autocomplete = {

    data() {
        return {
            geoLocality: '',
            geoCountryShort: '',
            geoCountry: '',
            geoLatitude: '',
            geoLongitude: '',
            searchType: 'location',
            nameText: '',
            isMobileSearch: false,
        }
    },

    methods: {

        onSearchSubmit(id) {

            if(this.searchType !== 'name') {
                document.getElementById(id).value = ''
                return
            }

            this.nameText = this.nameText.trim()

            if(this.nameText.length < 3) {
                this.$toast.global.my_error({message: 'Search term must to be at least 3 characters long'})
                return
            }

/*            const words = this.nameText.split(' ')

            const lengthCheck = words.some(word => {
                return word.length < 3 && word.length > 0
            })*/

            if(this.$helper.empty(this.nameText)) {
                this.$toast.global.my_error({message: 'Please provide a search term'})
                return
            }

            this.$router.push({ name: 'search.type.name', params: { type: 'name' }, query: { q: this.nameText } })
        },

        onTypeChanged(type) {
            this.searchType = type
            this.nameText = ''

        },

        onNameChanged(text) {
            this.nameText = text
        },

        onPlaceChanged: function (addressData, placeResultData, id) {

            if(this.isMobileSearch) {
                this.$bus.$emit('navigation/hide')
            }

            this.geoLocality = addressData.locality;
            this.geoCountryShort = addressData.country_short;
            this.geoCountry = addressData.country;
            this.geoLatitude = addressData.latitude;
            this.geoLongitude = addressData.longitude;

            var locality = this.geoLocality;

            if( typeof locality !== 'undefined' && locality !== null && locality != '' ){
                locality = locality.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                locality = locality.toLowerCase();
            } else {
                locality = false;
            }

            var country = this.geoCountryShort.toLowerCase();
            var countryName = this.geoCountry.toLowerCase();
            var countryNameSlug = this.$helper.url_slug(countryName);

            if( typeof country === 'undefined' || country === null || country == '' ) {
                document.getElementById(id).value = ''
                this.$toast.global.my_error({message: 'Please select a city or country from the list while typing'})
                //alert('Please select a city or country from the list while typing');
                return false;
            }

            document.getElementById(id).value = ''

            if(locality === false) {
                var routeParams = { name: 'search.type.country', params: { type: 'country', term: country, slug: countryNameSlug } }
            } else {
                var routeParams = { name: 'search.type.city', params: { type: 'city', country: country, slug: countryNameSlug, term: locality } }
            }

            const resolved = this.$router.resolve(routeParams)
            const currentPath = this.$router.currentRoute.path

            if(resolved.route.path !== currentPath) {
                this.$router.push(routeParams)
            }

        },

        onNoResults: function (place, id) {
            document.getElementById(id).value = ''
            this.$toast.global.my_error({message: 'Please select a city or country from the list while typing'})
            //alert('Please select a city or country from the list, while typing.');
        },

    },

}
