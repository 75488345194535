import {loadScript} from './utils'

export default (context, inject) => {
    const { store, app, $config } = context

    addListener(context).catch(console.error)
    handleScript(store, app, $config).catch(console.error)
}

async function handleScript(store, app, $config) {
    if(store.state.isCrawler === false) {
        const scriptUrl = $config.ccmScriptUrl

        window.onNuxtReady(() => {
            addScript(scriptUrl)
        })
    }
}

function addScript(scriptUrl) {
    const { set } = window.$nuxt.$meta().addApp('ccm')

    set({
        link: [
            { href: scriptUrl, rel: 'preload', as: 'script'}
        ],
        script: [
            { src: scriptUrl, referrerpolicy: 'origin' }
        ],
    })
}

async function addListener(context) {
    window.addEventListener("ccm19WidgetLoaded", widgetLoaded.bind(context))
    window.addEventListener("ccm19WidgetOpened", widgetOpened.bind(context))
    window.addEventListener('ccm19WidgetClosed', widgetClosed.bind(context))
    window.addEventListener("ccm19EmbeddingAccepted", embeddingAccepted.bind(context))
}

function widgetOpened(event) {
    //console.log('ccm/widgetOpened')
    checkConsent(this.store, this.$bus)
}

function widgetClosed() {
    //console.log('ccm/widgetClosed')
    checkConsent(this.store, this.$bus)
}

function widgetLoaded(event) {
    //console.log('ccm/widgetLoaded')
    this.store.commit('ccm/widgetLoaded', true)
    this.$bus.$emit('ccm/widgetLoaded')
}

function embeddingAccepted(event) {
    if(event.detail.name === 'Matomo') {
        this.store.commit('ccm/matomo', true)

        if(this.store.state.ccm.matomoScriptUrl) {
            const scriptUrl = this.store.state.ccm.matomoScriptUrl
            this.store.commit('ccm/matomoScriptUrl', false)

            //console.log('add script: ' + scriptUrl)

            loadScript(scriptUrl)
                .then(() => {
                    //console.log('script loaded')
                })
                .catch((error) => {
                    console.warn("Ops! Something happened and matomo.js couldn't be loaded", error);
                    return error;
                });
        }
    }

    if(event.detail.name === 'PostHog') {
        this.store.commit('ccm/posthog', true)
    }

    if(event.detail.name === 'AddThis') {
        this.store.commit('ccm/addthis', true)
    }
}

function checkConsent(store, bus) {
    let consent = false

    if(typeof window.CCM === 'object' && window.CCM.consent !== undefined) {
        consent = window.CCM.consent
    }

    //console.log('checkConsent', store.state.ccm.consent, window.CCM.consent)

    if(store.state.ccm.consent === consent) {
        return
    }

    store.commit('ccm/consent', consent)

    if(consent === true) {
        bus.$emit('ccm/consent')
    }
}
