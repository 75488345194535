class Calculator {

    constructor(state, getters) {
        this.state = state
        this.getters = getters
        this.prices = this.state
        this.currentPlan = this.getters['currentPlan']
        this.currentPriceTerm = this.state.currentPriceTerm
        this.selectedOptions = this.state.optionsSelected
    }

    recurringPrice() {
        return this.intervalPrice(this.currentPriceTerm)
    }

    intervalPrice(interval) {

        const planPrice = this.planPrice(interval)
        const optionsPrice = this.optionsPrice(interval)

        return planPrice + optionsPrice
    }

    monthlyPrice() {
        return this.intervalPrice('monthly')
    }

    yearlyPrice() {
        return this.intervalPrice('yearly')
    }

    planPrice(interval) {

        const price = this.currentPlan.pricing[interval].price
        var count = 0

        if(this.isBasic()) {
            count = this.prices.cities
        } else {
            count = this.prices.countries
        }

        const categoriesPrice = this.categoriesPrice(price, count)

        return (price * count) + categoriesPrice
    }

    onetimePrice() {

        var price = 0

        this.selectedOptions.forEach(option => {
            if(option.onetime === true) {
                price = price + this.calcOptionPriceOnetime(option)
            }
        })

        return price
    }

    optionsPrice(interval) {

        var price = 0

        this.selectedOptions.forEach(option => {
            if(option.onetime === false) {
                price = price + this.calcOptionPrice(option, interval)
            }
        })

        return price
    }

    calcOptionPrice(option, interval) {

        if(option.monthlyOnly && option.monthlyOnly === true) {

            if(interval === 'yearly') {
                return 0
            }

            return option.price
        }

        const price = option.pricing[interval].price
        var multiplier = 1

        if(option.quantifierType === 'value') {
            multiplier = option[option.quantifier]
        } else if (option.quantifierType === 'length') {
            multiplier = option[option.quantifier].length
        } else if (option.quantifierType === 'none') {
            multiplier = 1
        }

        return (price * multiplier)
    }

    optionsMonthlyOnlyPrice() {

        var price = 0

        this.selectedOptions.forEach(option => {
            if(option.monthlyOnly === true) {
                price = price + option.price
            }
        })

        return price
    }

    calcOptionPriceOnetime(option) {

        if(option.quantifier === undefined) {
            return option.price
        }

        const price = option.price
        var multiplier = 1

        if(option.quantifierType === 'value') {
            multiplier = option[option.quantifier]
        } else if (option.quantifierType === 'length') {
            multiplier = option[option.quantifier].length
        } else if (option.quantifierType === 'none') {
            multiplier = 1
        }

        return (price * multiplier)
    }

    isBasic() {
        return this.currentPlan.name === 'basic'
    }

    isPro() {
        return this.currentPlan.name === 'pro'
    }

    categoriesPrice(planPrice, planCount) {

        if(!this.state.categories.length || this.state.categories.length <= 0) {
            return 0
        }

        const price = planPrice / 2
        const categoriesCount = this.state.categories.length

        return (price * categoriesCount) * planCount
    }

}

export default Calculator
