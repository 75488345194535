
export default async function ({ redirect, $toast, $matomo, $bus, $sentry, $waiter }) {

    if(process.client) {

        $sentry.configureScope(scope => scope.setUser(null))

        $bus.$emit('auth/logout')

        const baseUrl = window.location.protocol +
            (window.location.protocol.slice(-1) === ':' ? '' : ':') +
            '//' +
            window.location.host

        $matomo.resetUserId()
        $matomo.setCustomUrl(baseUrl + '/logout')
        $matomo.appendToTrackingUrl('new_visit=1')
        $matomo.trackPageView()
        $matomo.appendToTrackingUrl('')

    }


    await sleep(2000)

    $toast.clear()

    $toast.global.my_success({message: 'Logged out'})

    $waiter.end('page')

    return redirect({name: 'index'})

}

function sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}
