export const state = () => ({
    category: false,
    newsView: 'grid',
    news: {},
    links: {},
    meta: {},
    upcomingEvents: {},
})

export const mutations = {
    setNewsView (state, value) {
        state.newsView = value
    },
    category(state, category) {
        state.category = category
    },
    SET_NEWS(state, news) {
        state.news = news
    },
    SET_LINKS(state, links) {
        state.links = links
    },
    SET_META(state, meta) {
        state.meta = meta
    },
    SET_UPCOMING_EVENTS(state, events) {
        state.upcomingEvents = events
    },

}

export const actions = {

    GET_UPCOMING_EVENTS ({ commit, state }) {

        //const response = await this.$axios.$get(this.$ziggy.route('api.media.upcoming'))
        //commit('SET_UPCOMING_EVENTS', response.events)

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.media.upcoming'))
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    GET_INITIAL_DATA ({ commit, state }, payload) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.media.initial.data'), {
                params: {
                    category: payload.category,
                    page: payload.page
                }
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })

        })
    },

    GET_CATEGORY ({ commit, state }, payload) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.media.category'), {
                params: {
                    category: payload.category,
                    page: payload.page
                }
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    GET_EVENT ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.media.event.detail'), {
                params: {
                    id: id,
                }
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    GET_EVENTS ({ commit, state }) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.media.events'))
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    GET_NEWS ({ commit, state }, id) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.media.news.detail'), {
                params: {
                    id: id,
                }
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },

    GET_MEMBER_NEWS ({ commit, state }, payload) {

        return new Promise((resolve, reject) => {

            this.$axios.$get(this.$ziggy.route('api.media.member.news'), {
                params: {
                    page: payload.page
                }
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })

        })
    },


}
