
export default function ({ $config, $logger, app, store, $bus }, inject) {

    //const TrackingManager = new Tracking(app.router, store, $bus)
    //TrackingManager.start().catch(console.error)

    install(app.router, store, $bus).catch((e) => $logger.captureException(e) )
}


async function install(router, store, bus) {

    let doTrack

    bus.$on('headChanged', metaInfo => {

        const status = store.state.tracking.status

        if (doTrack !== false && status === 'ready') {

            bus.$emit('track/run', doTrack)
            doTrack = false
        }

    })

    router.afterEach((to, from) => {

        const payload = { to, from }

        // track initial page view
        if (doTrack === undefined) {
            handleInitialPageview(store, bus, payload)
            doTrack = false
        } else {
            doTrack = payload
        }
    })

}

function handleInitialPageview(store, bus, payload) {

    if(store.state.ccm.widgetLoaded === true) {
        bus.$emit('track/run', payload)
        store.commit('tracking/trackInitialPageView', true)
        store.commit('tracking/payloadInitialPageView', payload)
    } else {

        bus.$on('ccm/widgetLoaded', () => {
            bus.$emit('track/run', payload)
            store.commit('tracking/trackInitialPageView', true)
            store.commit('tracking/payloadInitialPageView', payload)
        })

    }

}


/*
function handleInitialPageview(store, bus, payload) {

    let moep = payload
    moep.isInitial = true

    if(store.state.ccm.widgetLoaded === true) {
        bus.$emit('track/run', payload)
    } else {

        window.addEventListener("ccm19WidgetLoaded", widgetLoaded.bind(this))

/!*        bus.$on('ccm/widgetLoaded', () => {
            bus.$emit('track/run', moep)
        })*!/

    }

}

function widgetLoaded() {

    bus.$emit('track/run', payload)

}*/


/*class Tracking  {

        constructor(router, store, bus) {

            this.router = router
            this.store = store
            this.bus = bus
            this.payload = {}

        }

        async start() {

            this.addListenerHeadChanged()
            this.addListenerRoute()

        }

        addListenerHeadChanged() {

            this.bus.$on('headChanged', metaInfo => {

                const status = this.store.state.tracking.status

                if (this.doTrack !== false && status === 'ready') {

                    console.log('doTrack')

                    this.bus.$emit('track/run', this.doTrack)

                    this.doTrack = false
                }

            })

        }

        addListenerRoute() {

            this.router.afterEach((to, from) => {

                this.payload = {to, from}

                // track initial page view
                if (this.doTrack === undefined) {
                    this.handleInitialPageview()
                    this.doTrack = false
                } else {
                    this.doTrack = this.payload
                }
            })

        }


        handleInitialPageview() {

            this.payload.isInitial = true

            if (this.store.state.ccm.widgetLoaded === true) {
                this.bus.$emit('track/run', this.payload)
            } else {

                //window.addEventListener("ccm19WidgetLoaded", this.widgetLoaded.bind(this))

                this.bus.$on('ccm/widgetLoaded', () => {
                    this.bus.$emit('track/run', this.payload)
                })

            }

        }

    }*/
