
export default function (context, inject) {

    const logger = {

        checkSentry() {

            if(context.$sentry === null || !context.$sentry || context.$sentry === undefined) {
                return false
            }

            if(context.$config.sentryLazy === false) {
                return true
            }

            if(process.client && context.store.state.sentryLoaded === false) {
                context.$sentryLoad()
            }

            if(context.$auth.loggedIn) {
                context.$sentry.setUser({ id: context.$auth.user.id })
            }

        },

        captureException(e, scope = null) {

            let check = this.checkSentry()

            if(check === false) {
                return
            }

            context.$sentry.captureException(e, scope)
        },

        captureExceptionDebug(e, scope = null) {

            if(context.$config.debug === true) {
                this.captureException(e, scope)
            }

        },

        captureExceptionAxios(e, preventDialog = false) {

            let check = this.checkSentry()

            if(check === false) {
                return
            }

            const scope = new context.$sentry.Scope();

            scope.setContext('custom', {preventDialog})

            if(e.response) {

                const status = e.response.status

                scope.setContext('response', {
                    config: e.response.config,
                    data: e.response.data,
                    headers: e.response.headers,
                    status: status,
                });

                if(status === 404 || status === 403 || status === 422) {
                    this.captureExceptionDebug(e, scope)
                } else {
                    this.captureException(e, scope)
                }
            } else {

                this.captureException(e, scope)
            }

        },

        captureMessage(msg, level) {

            let check = this.checkSentry()

            if(check === false) {
                return
            }

            context.$sentry.captureMessage(msg, level)
        },

        captureMessageDebug(e) {

            if(context.$config.debug === true) {
                this.captureMessage(e)
            }

        },

        debug(...args) {

            if(context.$config.debug === true) {
                console.log('[DEBUG] ', ...args)
            }
        }

    }

    inject('logger', logger)
}
