export const state = () => ({
    searchQuery: '',
    searchCategory: '',
    searchCountry: '',
    countries: [],
    categories: [
        { key: 'service', name: 'Service' },
        { key: 'product', name: 'Product' },
    ]

})

export const mutations = {
    searchQuery(state, value) {
        state.searchQuery = value
    },
    searchCategory(state, value) {
        state.searchCategory = value
    },
    searchCountry(state, value) {
        state.searchCountry = value
    },
    countries(state, value) {
        state.countries = value
    }
}

export const actions = {
    updateSearchQuery ({ commit }, value) {
        commit('searchQuery', value)
    },
    updateSearchCategory ({ commit }, value) {
        commit('searchCategory', value)
    },
    updateSearchCountry ({ commit }, value) {
        commit('searchCountry', value)
    },
    updateCountries ({ commit }, value) {
        commit('countries', value)
    },
    clearSearchFilter ({ commit }) {
        commit('searchQuery', '')
        commit('searchCategory', '')
        commit('searchCountry', '')
    },
    updateSearchParams ({ state, commit }, params) {

        if(params.country && params.country !== '') {

            const country = state.countries.find(obj => obj.code === params.country)
            if(country) {
                commit('searchCountry', country)
            }

        }

        if(params.category && params.category !== '') {

            const category = state.categories.find(obj => obj.key === params.category)
            if(category) {
                commit('searchCategory', category)
            }

        }

        if(params.query && params.query !== '') {
            commit('searchQuery', params.query)
        }

    }
}
