//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    name: 'NavigationCrawler',

    data() {
        return {
            showDefault: true,
            featureAcademy: true,
            windowWidth:  0,
            windowHeight: 0,
            showMobile: false,
        }
    },

    methods: {

        navigationAllowed(navItem) {

            if(navItem.onlyGuest === true) {
                return true
            }

            if(navItem.onlyAuth === true) {
                return false
            }

            return true

        },

        checkWrapperOverlayHeight:  function () {

            let wrapper = this.$refs.navWrapperOverlay;

            if(this.showMobile === false) {
                wrapper.style.height = 0 + 'px'
            }

            wrapper.style.height = this.windowHeight + 'px'
        },

        toggleMobile: function () {

            this.showMobile = !this.showMobile

            this.checkWrapperOverlayHeight()

        },

        closeMobile: function () {
            this.showMobile = false
            this.checkWrapperOverlayHeight()
        },

    },


    computed: {

        navigation: function () {
            return this.$store.state.navigation
        },

    }

}

