export const state = () => ({

})

export const mutations = {

}

export const actions = {

  async FETCH_COURSE({ commit, state }, id) {
    try {
      const response = await this.$axios.$get(this.$ziggy.route('api.academy.course.detail', {course: id}))
      return response.data
    } catch (e) {
      this.$logger.captureExceptionAxios(e)
    }
  },

}
