export const state = () => ({
    statusReload: 'disabled',
    token: false,
})

export const mutations = {
    setToken (state, value) {
        state.token = value
    },
    setStatusReload (state, value) {
        state.statusReload = value
    },
}

export const actions = {

    CHECK_STATUS ({ commit, state }) {

        return new Promise((resolve, reject) => {

            this.$axios.get(this.$ziggy.route('api.status.get'))
                .then((response) => {

                    var authed = response.data.authed
                    var userId = response.data.userId

                    if(authed !== state.authed) {
                        commit('setStatusReload', 'reload')
                    } else if (authed === true && userId !== state.user.id ) {
                        commit('setStatusReload', 'reload')
                    }

                    //console.log('status: ' + authed + ' ' + userId)
                    //console.log('state: ' + state.authed + ' ' + state.user.id)

                    resolve(response)
                })
                .catch((error) => {
                    commit('setStatusReload', 'cancel')
                    reject(error)
                })
        })
    },

    GET_TOKEN ({ commit, state }) {

        return new Promise((resolve, reject) => {

            this.$axios.get('/sanctum/csrf-cookie').then(response => {

                this.$axios.get(this.$ziggy.route('api.status.echo.token', {}, false))
                    .then((response) => {

                        var token = response.data.token
                        commit('setToken', token)

                        resolve(response)
                    })
                    .catch((error) => {
                        commit('setToken', false)
                        reject(error)
                    })

            });

        })
    },

}
