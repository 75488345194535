export default function ({store, $bus, $axios, $ziggy}) {
    window.onNuxtReady(() => addTimer(store, $bus, $axios, $ziggy).catch(console.error))
}

async function addTimer(store, $bus, $axios, $ziggy) {

    const pingTime = 5 * 60 * 1000 // 5 minutes
    const pingUrl = $ziggy.route('api.status.ping')
    const intervalID = setInterval(ping, pingTime, $axios, pingUrl)

}

function ping($axios, pingUrl) {
    $axios.get(pingUrl)
}
