
export default async function ({ redirect, $auth, $ziggy, $axios, params, route, $logger, $helper }) {

    try {
        const result = await $axios.$get($ziggy.route('verification.verify', {id: params.id, hash: params.hash}), {
                params: {
                    expires: route.query.expires,
                    signature: route.query.signature
                }
            })

    } catch (e) {
        $logger.captureExceptionAxios(e)
    }

    await $auth.fetchUser()

    const target = $helper.userRedirectTo($auth.user, $ziggy)
    return redirect(target)

}
