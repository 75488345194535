import lazyImageObserver from './lazyObserver'
import constant from './constant'

const plugin = {
    install: Vue => {
        Vue.directive('lazyload-bg', {

            bind (el) {
                if ('IntersectionObserver' in window) {
                    lazyImageObserver.observe(el)
                }
            },

            componentUpdated (el) {
                // when image changed
                // expecting has been loaded image before
                if ('IntersectionObserver' in window) {
                    if (el.classList.contains(constant._V_LOADED) === false && el.classList.contains(constant._V_ERROR) === false) {
                        lazyImageObserver.observe(el)
                    }
                }
            }

        })
    }
}

export default plugin
