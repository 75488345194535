//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import LazyHydrate from "vue-lazy-hydration";

export default {

    name: 'ErrorLayout',

    components: { LazyHydrate },

    watch: {

        matomo(val) {
            this.$matomo.setConsent(val)
        },

        posthog(val) {
            if(this.$PostHog) {
                this.$PostHog.start(this.$auth)
            }
        }

    },

    head: {
        afterNavigation(metaInfo) {
            //console.log('afterNavigation')
            $nuxt.$bus.$emit('afterNavigation', metaInfo)
        },
        changed(newInfo, addedTags, removedTags) {
            //console.log('headChanged layout', newInfo, addedTags, removedTags)
            $nuxt.$bus.$emit('headChanged', newInfo)
        }
    },

    created() {

        if (process.client) {

            //console.log('addEventListener nuxtIdle')

            window.addEventListener('nuxtIdle',e => {

                if(this.$googleApi.isLoaded() === false) {
                    this.$googleApi.load()
                }

            }, false)

        }

        this.$auth.$storage.watchState('loggedIn', loggedIn => {
            //console.log('watchState', loggedIn)

            if(loggedIn) {
                this.$sentry.setUser({ id: this.$auth.user.id })
            }

        })

    },

    computed: {

        matomo() {
            return this.$store.state.ccm.matomo
        },
        posthog() {
            return this.$store.state.ccm.posthog
        },
        wrapperClass: function () {
            return this.$store.state.wrapperClass
        },
        fontsLoaded: function () {
            return this.$store.state.fontsLoaded
        },
        isCrawler() {
            return this.$store.state.isCrawler
        },
        isWaitingPage: function() {
            return this.$waiter.has('page')
        },
        hideFooter: function () {
            return this.$store.state.hideFooter
        }
    },
}
