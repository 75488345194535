let errorListener, rejectionListener


export default function (context)  {

    if(context.$config.sentryLazy === true) {
        addListener(context).catch(console.error)
    }

}

async function addListener(context) {

    if(context.$auth.loggedIn) {
        context.$sentry.setUser({ id: context.$auth.user.id })
    }

    window.addEventListener('error', onError.bind(context))
    window.addEventListener('unhandledrejection', onUnhandledRejection.bind(context))

    context.$sentryReady().then(() => {
        removeListener()
        context.store.commit('setSentryLoaded', true)
    })

}

function removeListener() {

    window.removeEventListener('error', onError)
    window.removeEventListener('unhandledrejection', onUnhandledRejection)

}

function onError() {

    this.$sentryLoad()
    removeListener()

}

function onUnhandledRejection() {

    this.$sentryLoad()
    removeListener()

}
