
export default function({ app, $waiter}) {

    const router = app.router

    handleLoader(router, $waiter).catch(console.error)

}

async function handleLoader(router, $waiter) {

    router.beforeEach((to, from, next) => {

        //console.log('beforeEach', to.meta.module, from.meta.module)

        if(!to.meta.module) {
            next()
        } else if( to.meta.module === from.meta.module ) {
            next()
        } else {
            $waiter.throttle('page', 300)
            next()
        }

    })

    router.afterEach((to, from) => {
        $waiter.end('page')
    })

}
