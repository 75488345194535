
export default function ({ store, app })  {

    handleFonts(store, app).catch(console.error)

}

async function handleFonts(store, app) {

    if(window.__FONTS_LOADED__ === true) {
        //console.log('FONTS already loaded', window.__FONTS_LOADED__)
        setFontsLoaded(store, app)
    } else {

        //console.log('FONTS not loaded', window.__FONTS_LOADED__)

        let _windowFontsLoaded
        Object.defineProperty(window, '__FONTS_LOADED__', {
            configurable: true,
            enumerable: true,
            get () {
                return _windowFontsLoaded
            },
            set (newVal) {

                if (_windowFontsLoaded) {
                    debug(`window.__FONTS_LOADED__ is already defined`)
                }

                _windowFontsLoaded = newVal

                if(newVal === true) {
                    //console.log('FONTS loaded', newVal)
                    setFontsLoaded(store, app)
                }

            }
        })

    }

}

function setFontsLoaded(store, app) {

    app.$cookies.set('fonts-loaded', true, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30 // 30 days
    })

    store.commit('setFontsLoaded', true)

/*    window.onNuxtReady(() => {
        setHtmlClass()
    })*/


}

function setHtmlClass() {

    const { set } = window.$nuxt.$meta().addApp('fonts')

    set({
        htmlAttrs: {
            class: ['fonts-loaded']
        }
    })

}
