
export default ({ app }) => {

    const options = {
        refreshOnceOnNavigation: true
    }

    if (process.client) {

        //addChangedListener(app).catch(console.error)

        window.onNuxtReady(() => {
            window.$nuxt.$meta().setOptions(options)
        })

    }

};

async function addChangedListener(app) {

    //console.log('addChangedListener')

    const changed = app.head.changed
    app.head.changed = (...args) => {

        console.log('headChanged plugin', args[0])

        window.$nuxt.$emit('headChanged', args[0])

        if (changed && isFn(changed)) {
            changed.call(null, ...args)
        }
    }

}

function isFn (fn) {
    return typeof fn === 'function'
}
