export const state = () => ({
    status: 'ready',
    trackInitialPageView: false,
    payloadInitialPageView: {},
    documentTitle: false,

})

export const mutations = {
    status (state, status) {
        state.status = status
    },
    trackInitialPageView (state, status) {
        state.trackInitialPageView = status
    },
    payloadInitialPageView (state, payload) {
        state.payloadInitialPageView = payload
    },
    documentTitle (state, title) {
        state.documentTitle = title
    },
}
