const middleware = {}

middleware['crawler-detect'] = require('../middleware/crawler-detect.js')
middleware['crawler-detect'] = middleware['crawler-detect'].default || middleware['crawler-detect']

middleware['email-verification'] = require('../middleware/email-verification.js')
middleware['email-verification'] = middleware['email-verification'].default || middleware['email-verification']

middleware['fonts'] = require('../middleware/fonts.js')
middleware['fonts'] = middleware['fonts'].default || middleware['fonts']

middleware['impersonate-redirect'] = require('../middleware/impersonate-redirect.js')
middleware['impersonate-redirect'] = middleware['impersonate-redirect'].default || middleware['impersonate-redirect']

middleware['login-redirect'] = require('../middleware/login-redirect.js')
middleware['login-redirect'] = middleware['login-redirect'].default || middleware['login-redirect']

middleware['logout-redirect'] = require('../middleware/logout-redirect.js')
middleware['logout-redirect'] = middleware['logout-redirect'].default || middleware['logout-redirect']

middleware['matomo'] = require('../middleware/matomo.js')
middleware['matomo'] = middleware['matomo'].default || middleware['matomo']

middleware['menu'] = require('../middleware/menu.js')
middleware['menu'] = middleware['menu'].default || middleware['menu']

middleware['not-verified'] = require('../middleware/not-verified.js')
middleware['not-verified'] = middleware['not-verified'].default || middleware['not-verified']

middleware['user-agent'] = require('../middleware/user-agent.js')
middleware['user-agent'] = middleware['user-agent'].default || middleware['user-agent']

export default middleware
