//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

	import handlers from './mixins/handlers'
	import helpers from './mixins/helpers'
	import methods from './mixins/methods'
	import preparations from './mixins/preparations'
	import settings from './mixins/settings'
	import throttle from './mixins/throttle'
	import watchers from './mixins/watchers'

	export default {
		name: 'Agile',

		mixins: [handlers, helpers, methods, preparations, settings, throttle, watchers],

		data () {
			return {
				autoplayInterval: null,
				autoplayRemaining: null,
				autoplayStartTimestamp: null,
				autoplayTimeout: null,
				currentSlide: null,
				dragDistance: 0,
				dragStartX: 0,
				dragStartY: 0,
				isAutoplayPaused: false,
				isMouseDown: false,
				slides: [],
				slidesClonedAfter: [],
				slidesClonedBefore: [],
				isSSR: (typeof window === 'undefined'),
				transitionDelay: 0,
				translateX: 0,
				widthWindow: 0,
				widthContainer: 0
			}
		},

		computed: {
			breakpoints: function () {
				return (!this.initialSettings.responsive) ? [] : this.initialSettings.responsive.map(item => item.breakpoint)
			},

			computedSlidesToScroll() {
				// should never be greater than the slide count
				return Math.min(this.settings.slidesToScroll, this.countSlides)
			},

			previousSlide() {
				let prev = this.currentSlide - this.computedSlidesToScroll

        if(this.settings.infinite) {
          return prev
        }

        if(prev < 0) {
          prev = 0
        }

        return prev
			},

			nextSlide() {
				let next =  this.currentSlide + this.computedSlidesToScroll

        if(this.settings.infinite) {
          return next
        }

        if( (this.computedSlidesToScroll + this.countViewed) > this.countSlides ) {
          next = this.currentSlide + ( this.countSlides - this.countViewed )
        }

        return next
			},

			canGoToPrev: function () {
				return (this.settings.infinite || ( this.previousSlide >= 0 &&  this.currentSlide !== 0 ) )
			},

			canGoToNext: function () {

        if(this.settings.infinite) {
          return true
        }

        if(this.countViewed < this.countSlides ) {
          return true
        }

        if(this.countViewed >= this.countSlides ) {
          return false
        }

        return false
			},

      countViewed: function () {
			  return this.currentSlide + this.settings.slidesToShow
      },

			countSlides: function () {
				return (this.isSSR) ? this.htmlCollectionToArray(this.$slots.default).length : this.slides.length
			},

			countSlidesAll: function () {
				return this.slidesAll.length
			},

			currentBreakpoint: function () {
				let breakpoints = this.breakpoints.map(item => item).reverse()
				return (this.initialSettings.mobileFirst) ? breakpoints.find(item => item < this.widthWindow) || 0 : breakpoints.find(item => item > this.widthWindow) || null
			},

			marginX: function () {
				if (this.settings.unagile) {
					return 0
				}

				let marginX = (this.slidesCloned) ? this.countSlides * this.widthSlide : 0

				// Center mode margin
				if (this.settings.centerMode) {
					marginX -= (Math.floor(this.settings.slidesToShow / 2) - +(this.settings.slidesToShow % 2 === 0)) * this.widthSlide
				}

				return (this.settings.rtl) ? marginX : -1 * marginX
			},

			slidesCloned: function () {
				return (!this.settings.unagile && !this.settings.fade && this.settings.infinite)
			},

			slidesAll: function () {
				return (this.slidesCloned) ? [...this.slidesClonedBefore, ...this.slides, ...this.slidesClonedAfter] : this.slides
			},

			widthSlide: function () {
				return (!this.settings.unagile) ? this.widthContainer / this.settings.slidesToShow : 'auto'
			}
		},

		mounted () {
			// Windows resize listener
			window.addEventListener('resize', this.getWidth)

			// Mouse and touch events
			this.$refs.track.addEventListener('touchstart', this.handleMouseDown)
			this.$refs.track.addEventListener('touchend', this.handleMouseUp)
			this.$refs.track.addEventListener('touchmove', this.handleMouseMove)
			this.$refs.track.addEventListener('mousedown', this.handleMouseDown)
			this.$refs.track.addEventListener('mouseup', this.handleMouseUp)
			this.$refs.track.addEventListener('mousemove', this.handleMouseMove)

			// Init
			this.isSSR = false
			this.reload()
		},

		beforeDestroy () {
			window.removeEventListener('resize', this.getWidth)

			this.$refs.track.removeEventListener('touchstart', this.handleMouseDown)
			this.$refs.track.removeEventListener('touchend', this.handleMouseUp)
			this.$refs.track.removeEventListener('touchmove', this.handleMouseMove)
			this.$refs.track.removeEventListener('mousedown', this.handleMouseDown)
			this.$refs.track.removeEventListener('mouseup', this.handleMouseUp)
			this.$refs.track.removeEventListener('mousemove', this.handleMouseMove)

			this.disableAutoPlay()
		},

		methods: {
			// Return current breakpoint
			getCurrentBreakpoint () {
				return this.currentBreakpoint
			},

			// Return settings for current breakpoint
			getCurrentSettings () {
				return this.settings
			},

			// Return current slide index
			getCurrentSlide () {
				return this.currentSlide
			},

			// Return initial settings
			getInitialSettings () {
				return this.initialSettings
			},

			// Go to slide
			goTo (n, transition = true, asNav = false) {
				// Break goTo() if unagile is active
				if (this.settings.unagile) {
					return false
				}

				if (!asNav) {
					this.settings.asNavFor.forEach(carousel => {
						if (carousel) {
							carousel.goTo(n, transition, true)
						}
					})
				}

				let slideNextReal = n

				if (transition) {
					if (this.settings.infinite && n < 0) {
						// example n=-1; countSlides=5; the real index of slide 5 is 4 (-1 + 5 = 4)
						slideNextReal = n + this.countSlides
					} else if (this.settings.infinite && n >= this.countSlides) {
						// example n=5; countSlides=5; the real index of slide 1 is 0 (5 - 5 = 0)
						slideNextReal = n - this.countSlides
					} else if (n >= this.countSlides) {
						slideNextReal = 0
					}

					this.$emit('before-change', { currentSlide: this.currentSlide, nextSlide: slideNextReal })

					this.currentSlide = slideNextReal

					if (n !== slideNextReal) {
						setTimeout(() => {
							this.goTo(slideNextReal, false)
						}, this.settings.speed)
					}
				}

				let translateX = (!this.settings.fade) ? n * this.widthSlide : 0
				this.transitionDelay = (transition) ? this.speed : 0

				if (this.infinite || (this.currentSlide + this.slidesToShow <= this.countSlides)) {
					this.translateX = (this.settings.rtl) ? translateX : -1 * translateX
				}
			},

			// Go to next slide
			goToNext () {
				if (this.canGoToNext) {
					this.goTo(this.nextSlide)
				}
			},

			// Go to previous slide
			goToPrev () {
				if (this.canGoToPrev) {
					this.goTo(this.previousSlide)
				}
			},

			// Reload carousel
			reload () {
				this.getWidth()
				this.prepareSlides()
				this.prepareCarousel()
				this.toggleFade()
				this.toggleAutoPlay()
			}
		}
	}
