import Calculator from "~/components/prices/calculator";

export const state = () => ({

    plans: [
        {
            name: "free",
            title: "One destination",
            popular: false,
            pricing: undefined,
            action: "Go free",
            features: [
                "Random order listing for 1 city",
            ],
        },
        {
            name: "basic",
            title: "Extra city listing",
            popular: false,
            pricing: {
                monthly: {price: 1000, label: 'month'},
                yearly: {price: 7200, label: 'year'}
            },
            identifier: 'basic_new',
            action: "Get started",
            features: [
                "Listing on the top of the first page results for city",
                "Highlighted listing with BASIC batch",
            ],
        },
        {
            name: "pro",
            title: "Premium for entire country",
            popular: true,
            pricing: {
                monthly: {price: 2900, label: 'month'},
                yearly: {price: 20880, label: 'year'}
            },
            identifier: 'pro_new',
            action: "Get started",
            features: [
                "Bigger highlighted listing with PRO batch",
                "Listing on the top of the first page results",
                "Listed for all search requests for entire countries",
            ],
        },
    ],

    individualOptions: [
        {
            id: 1,
            name: "Enhance Your Status",
            icon: "enhance-your-status",
            options: [
                {
                    id: 'verified-member',
                    name: "Verified Member",
                    price: 19900,
                    onetime: true,
                    identifier: 'verified_member',
                    description: "One Time Fee: 199 Euro",
                    icon: "verified-member",
                    iconVar: 'verified',
                    allowed: 'canBuyVerifiedMember',
                    popover: 'verified',
                },
                {
                    id: 'gmcs',
                    name: "GMCS Standard",
                    price: 189900,
                    onetime: true,
                    identifier: 'gmcs_provider_normal',
                    description: "One Time Fee: 1899 Euro",
                    icon: "gmcs-Logo",
                    iconVar: 'gmcs',
                    allowed: 'canBuyGMCS',
                    popover: 'gmcs',
                },
            ],

        },
        {
            id: 2,
            name: "Promote Products/Services",
            icon: "promote-products",
            options: [
                {
                    id: 'premium-product',
                    name: "Premium Product",
                    pricing: {
                        monthly: {price: 2900, label: 'month'},
                        yearly: {price: 31320, label: 'year'}
                    },
                    onetime: false,
                    identifier: 'profile_product_premium',
                    quantifier: 'quantity',
                    quantifierType: 'value',
                    description: "Monthly: 29 Euro",
                    icon: undefined,
                    quantity: 1,
                },
                {
                    id: 'premium-banner',
                    name: "Premium Banner",
                    pricing: {
                        monthly: {price: 8900, label: 'month'},
                        yearly: {price: 96120, label: 'year'}
                    },
                    onetime: false,
                    identifier: 'profile_product_banner',
                    quantifier: 'quantity',
                    quantifierType: 'value',
                    description: "Monthly: 89 Euro",
                    icon: undefined,
                    quantity: 1
                },
            ],

        },
        {
            id: 3,
            name: "Advertise Your Profile",
            icon: "your-profile",
            options: [
                {
                    id: 'advertise-profile',
                    name: "Advertise Your Profile",
                    pricing: {
                        monthly: {price: 3900, label: 'month'},
                        yearly: {price: 42120, label: 'year'}
                    },
                    onetime: false,
                    identifier: 'advertise_profile',
                    quantifier: 'countries',
                    quantifierType: 'length',
                    description: "per country monthly: 39 Euro",
                    descriptionPrefix: "per country",
                    icon: undefined,
                    countries: [],
                    option: 'visitor',
                    extras: ['countries', 'option'],
                    errorMessage: 'Please select a country for "Advertise Your Profile"'
                },
                {
                    id: 'logo-start-page',
                    name: "Logo on start page",
                    pricing: {
                        monthly: {price: 5000, label: 'month'},
                        yearly: {price: 54000, label: 'year'}
                    },
                    onetime: false,
                    identifier: 'logo_on_startpage',
                    quantifierType: 'none',
                    description: "monthly: 50 Euro",
                    icon: undefined,
                    allowed: 'canBuyLogoOnStartPage'
                },
            ],

        },
        {
            id: 4,
            name: "Media Options",
            icon: "media-options",
            options: [
                {
                    id: 'media-article',
                    name: "Online article",
                    price: 50000,
                    onetime: true,
                    identifier: 'media_online_article',
                    description: "6 month 500 Euro",
                    icon: undefined,
                },
                {
                    id: 'media-social-media',
                    name: "Social media feature",
                    price: 30000,
                    onetime: true,
                    identifier: 'media_social_media_feature',
                    description: "6 month 500 Euro",
                    icon: undefined,
                },
                {
                    id: 'media-newsletter-topic',
                    name: "Newsletter topic",
                    price: 100000,
                    onetime: true,
                    identifier: 'media_newsletter_topic',
                    description: "6 month 500 Euro",
                    icon: undefined,
                },
                {
                    id: 'media-email-campaign',
                    name: "Individual email campaign",
                    price: 479900,
                    onetime: true,
                    identifier: 'media_individual_email_campaign',
                    description: "One Time Fee 4.799 Euro",
                    icon: undefined,
                },
                {
                    id: 'media-premium-job-offer',
                    name: "Premium job offer",
                    price: 9900,
                    onetime: true,
                    identifier: 'media_premium_job_offer',
                    description: "One Time Fee 99 Euro",
                    icon: undefined,
                },
            ],
        },
        {
            id: 5,
            name: "Advertising Options",
            icon: "adv-option",
            options: [
                {
                    id: 'adv-banner-start-page',
                    name: "Banner on start page 728 x 90",
                    price: 79900,
                    onetime: false,
                    monthlyOnly: true,
                    identifier: 'advertising_banner_start_page',
                    quantifier: 'quantity',
                    quantifierType: 'value',
                    description: "monthly: 799 Euro",
                    icon: undefined,
                    quantity: 1,
                    extras: ['quantity'],
                },
                {
                    id: 'adv-banner-result-worldwide',
                    name: "Banner worldwide on results page 248 x 367",
                    price: 279900,
                    onetime: false,
                    monthlyOnly: true,
                    identifier: 'advertising_banner_worldwide_results',
                    quantifier: 'quantity',
                    quantifierType: 'value',
                    description: "monthly: 2.799 Euro",
                    icon: undefined,
                    quantity: 1,
                    extras: ['quantity'],
                },
                {
                    id: 'adv-banner-country',
                    name: "Banner per country visitor or searched",
                    price: 49900,
                    pricing: {
                        monthly: {price: 49900, label: 'month'},
                        yearly: {price: 598800, label: 'year'}
                    },
                    onetime: false,
                    identifier: 'advertising_banner_country',
                    quantifier: 'countries',
                    quantifierType: 'length',
                    description: "Per Country 499 Euro",
                    descriptionPrefix: "per country",
                    icon: undefined,
                    countries: [],
                    option: 'visitor',
                    extras: ['countries', 'option'],
                    errorMessage: 'Please select a country for "Banner Country"'
                },
                {
                    id: 'adv-banner-email',
                    name: "Email Banner",
                    price: 79900,
                    onetime: true,
                    identifier: 'advertising_email_banner',
                    quantifier: 'quantity',
                    quantifierType: 'value',
                    description: "One Time Fee 799 Euro",
                    descriptionPrefix: "per email",
                    icon: undefined,
                    quantity: 1
                },

            ],
        },
    ],

    // Frequently Asked Qustions
    faqs: [
        {
            id: 1,
            question: '1. What is EVINTRA?',
            answer: 'EVINTRA is a German company with the main office in Frankfurt providing a directory for the MICE & Tourism industry, free of charge to register, to use and without any fee or commission. The directory is on the one hand online on evintra.com, as well as in print version, published twice a year with 10.000 exemplars per year.'
        },
        {
            id: 2,
            question: '2. For who is EVINTRA?',
            answer: 'EVINTRA is for all stakeholders in the MICE and Tourism industry. Buyers can use our directory to find suppliers anywhere in the world – Supplier (Agencies, DMCs, Hotels, Event Locations, Tourism Companies, Convention Bureaus,…), so provider for any kind of MICE services, can register here to get found by Buyers.'
        },
        {
            id: 3,
            question: '3. What does the free profile include?',
            answer: 'The free profile includes a random listing for search requests going to one city you can choose and the respective country. Your profile will be directly online after registering the “Provider” profile and will be published with your contact details in the next EVINTRA PRINT EDITION. '
        },
    ],

    currentPriceTerm: 'monthly',
    priceTermSelectors: ['monthly', 'yearly'],
    plansSelect: ['basic', 'pro'],
    currentPlanSelected: 'pro',

    featureListed: false,


    optionsSelected: [],

    categories: [],
    cities: 1,
    countries: 1,
    categoriesSelect: [],

    canBuyVerifiedMember: true,
    canBuyGMCS: true,

    errors: [],


})

export const getters = {

    plans: state => state.plans,

    individualOptionsAll: state => state.individualOptions,

    individualOptions: (state) => {

        const filtered = state.individualOptions.filter(option => {

            var subFiltered = option.options.filter(option => {

                if(option.allowed) {
                    return state[option.allowed]
                }

                return true
            })

            if(!subFiltered.length) {
                return false
            }

            return true
        })

        return filtered

    },

    priceTermSelectors: state => state.priceTermSelectors,
    plansSelect: state => state.plansSelect,
    faqs: state => state.faqs,

    categoriesSelect: state => state.categoriesSelect,

    // currentPlanSelected: (state) => state.currentPlanSelected,

    currentPlan: (state) => {
        return state.plans.find(plan => plan.name === state.currentPlanSelected)
    },

    isSelected: (state) => (option) => {
        return state.optionsSelected.find(selected => selected.id === option.id)
    },

    countriesAdvertiseProfile: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'advertise-profile')

        if(option) {
            return option.countries
        }

        return []
    },

    countriesBannerCountries: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-country')

        if(option) {
            return option.countries
        }

        return []
    },

    quantityPremiumProducts: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'premium-product')

        if(option) {
            return option.quantity
        }

        return 0
    },

    quantityPremiumBanner: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'premium-banner')

        if(option) {
            return option.quantity
        }

        return 0
    },

    quantityBannerStartPage: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-start-page')

        if(option) {
            return option.quantity
        }

        return 0
    },

    quantityBannerResultWorld: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-result-worldwide')

        if(option) {
            return option.quantity
        }

        return 0
    },

    quantityBannerEmail: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-email')

        if(option) {
            return option.quantity
        }

        return 0
    },

    optionBannerCountries: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-country')

        if(option) {
            return option.option
        }

        return 'visitor'
    },

    optionAdvertiseProfile: (state) => {
        var option = state.optionsSelected.find(el => el.id === 'advertise-profile')

        if(option) {
            return option.option
        }

        return 'visitor'
    },

    recurringPrice: (state, getters) => {
        const calcer = new Calculator(state, getters)
        return calcer.recurringPrice()
    },

    monthlyPrice: (state, getters) => {
        const calcer = new Calculator(state, getters)
        return calcer.monthlyPrice()
    },

    yearlyPrice: (state, getters) => {
        const calcer = new Calculator(state, getters)
        return calcer.yearlyPrice()
    },

    onetimePrice: (state, getters) => {
        const calcer = new Calculator(state, getters)
        return calcer.onetimePrice()
    },

    monthlyOnlyPrice: (state, getters) => {
        const calcer = new Calculator(state, getters)
        return calcer.optionsMonthlyOnlyPrice()
    },


}

export const mutations = {

    currentPriceTerm: (state, currentPriceTerm) => state.currentPriceTerm = currentPriceTerm,
    currentPlanSelected: (state, currentPlanSelected) => state.currentPlanSelected = currentPlanSelected,
    featureListed: (state, featureListed) => state.featureListed = featureListed,
    optionsSelected: (state, optionsSelected) => state.optionsSelected = optionsSelected,

    categories: (state, categories) => state.categories = categories,
    countries: (state, countries) => state.countries = countries,
    cities: (state, cities) => state.cities = cities,
    errors: (state, errors) => state.errors = errors,

    categoriesSelect: (state, categories) => state.categoriesSelect = categories,

    canBuyVerifiedMember: (state, value) => state.canBuyVerifiedMember = value,
    canBuyGMCS: (state, value) => state.canBuyGMCS = value,

    REMOVE_Selected_Option(state, option) {

        const selectedOption = state.optionsSelected.find(el => el.id === option.id)

        if(selectedOption) {

            const index = state.optionsSelected.indexOf(selectedOption)
            if (index > -1) {
                state.optionsSelected.splice(index, 1)
            }

        }
    },

    ADD_Selected_Option(state, option) {
        state.optionsSelected.push(option)
    },

    REMOVE_Option(state, payload) {

        const type = payload.type
        const option = payload.option

        const selectedOption = state[type].find(el => el === option)

        if(selectedOption) {

            const index = state[type].indexOf(selectedOption)
            if (index > -1) {
                state[type].splice(index, 1)
            }
        }
    },

    countriesAdvertiseProfile(state, countries) {
        var option = state.optionsSelected.find(el => el.id === 'advertise-profile')

        if(option) {
            option.countries = countries
        }
    },

    countriesBannerCountries(state, countries) {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-country')

        if(option) {
            option.countries = countries
        }
    },

    quantityPremiumProducts(state, quantity) {
        var option = state.optionsSelected.find(el => el.id === 'premium-product')

        if(option) {
            option.quantity = quantity
        }
    },

    quantityPremiumBanner(state, quantity) {
        var option = state.optionsSelected.find(el => el.id === 'premium-banner')

        if(option) {
            option.quantity = quantity
        }
    },

    quantityBannerStartPage(state, quantity) {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-start-page')

        if(option) {
            option.quantity = quantity
        }
    },

    quantityBannerResultWorld(state, quantity) {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-result-worldwide')

        if(option) {
            option.quantity = quantity
        }
    },

    quantityBannerEmail(state, quantity) {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-email')

        if(option) {
            option.quantity = quantity
        }
    },

    optionBannerCountries(state, value) {
        var option = state.optionsSelected.find(el => el.id === 'adv-banner-country')

        if(option) {
            option.option = value
        }
    },

    optionAdvertiseProfile(state, value) {
        var option = state.optionsSelected.find(el => el.id === 'advertise-profile')

        if(option) {
            option.option = value
        }
    },

    reset(state) {
        state.optionsSelected = []
        state.categories = []
        state.cities = 1
        state.countries = 1
        state.currentPriceTerm = 'monthly'
        state.currentPlanSelected = 'pro'
        state.featureListed = false
        state.errors = []
        state.canBuyVerifiedMember = true
        state.canBuyGMCS = true
        state.categoriesSelect = []
    }
}

export const actions = {}
