/*import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')*/

import EchoManager from './echo-utils'

async function initEcho(context, inject) {
  const { $config, app, store, $axios, $bus, $auth } = context

  if(store.state.isCrawler === true) {
    return
  }

  const Echo = (await import('laravel-echo' /* webpackChunkName: 'laravel-echo' */)).default
  window.Pusher = (await import('pusher-js' /* webpackChunkName: 'pusher' */)).default

  const options = {"broadcaster":"pusher","plugins":null,"authModule":false,"connectOnLogin":false,"disconnectOnLogout":false,"key":"2bo7GFiKM6FxPcGkJBeD","wsHost":"testing.evintra.com","wsPath":"\u002Fws","pongTimeout":10000,"forceTLS":true,"enabledTransports":["ws"]}

  options.authorizer = (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        $axios.post('/api/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        })
        .then(response => {
          callback(false, response.data);
        })
        .catch(error => {
          callback(true, error);
        });
      }
    }
  }

  const echo = new Echo(options)

  var Manager = new EchoManager(echo, store, $bus, $axios, $auth)

  context.$echo = echo
  inject('echo', echo)
}

export default (context, inject) => {
  if(context.store.state.features['nuxt_echo'] === true) {
    window.onNuxtReady(() => initEcho(context, inject).catch(console.error))
  }
}
