
export default function ({ $bus, $toast }) {

    registerEvents($bus, $toast)

}

async function registerEvents($bus, $toast) {

    let reloadToasts = $toast.group({
        theme: 'toasted-primary',
        iconPack : 'fontawesome',
        position: 'top-center',
        closeOnSwipe: false,
        singleton: true,
    })

    $bus.$on('release/new', payload => {

        reloadToasts.error("A new version of this site is available.", {
            icon: {
                name: 'fa-sync'
            },
            containerClass: 'toasted-evintra-reload',
            action: [
                {
                    text : 'Reload',
                    class: 'toasted-evintra-reload-action',
                    onClick : (e, toastObject) => {
                        window.location.reload(true)
                    }
                },
                {
                    text : 'Dismiss',
                    class: 'toasted-evintra-reload-action',
                    onClick : (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                }
            ]
        })

    })


}
