import VuexPersistence from 'vuex-persist'

export default (context) => {

    const { app: { router, store } } = context

    new VuexPersistence({
        //modules: ['search'],
        reducer: reducer,
    }).plugin(store);

/*
    const waitForStorageToBeReady = async (to, from, next) => {
        await store.restored
        next()
    }

    router.beforeEach(waitForStorageToBeReady)*/

}

function reducer(state) {

    const search = {
        favoriteCart: state.search.favoriteCart,
        requestCart: state.search.requestCart,
    }

    return {
        search: search
    }

}
