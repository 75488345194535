//import {Crawler} from 'es6-crawler-detect'

export default ({ store, app, userAgent }) => {

    if (process.server) {

        //var CrawlerDetector = new Crawler();

        const fontsLoaded = app.$cookies.get('fonts-loaded')

        /*if(fontsLoaded || CrawlerDetector.isCrawler(userAgent)) {*/
        if(fontsLoaded) {

            const { set } = app.context.ssrContext.meta.addApp('fonts')

            set({
                htmlAttrs: {
                    class: ['fonts-loaded']
                }
            })

            store.commit('setFontsLoaded', true)

        }

    }
}
