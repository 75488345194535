
export default async function({ store, userAgent }) {

    if (process.server) {

        if(typeof userAgent === 'undefined' || userAgent === null || userAgent === '') {
            return
        }

        const CrawlerLib = await import('es6-crawler-detect')

        var CrawlerDetector = new CrawlerLib.Crawler();

        if(CrawlerDetector.isCrawler(userAgent)) {
            store.commit('setIsCrawler', true)
        }

    }
}
