
export default async function ({ redirect, $auth, $ziggy, $helper }) {

    await $auth.fetchUser()

    if($auth.user.verified === true) {
        const target = $helper.userRedirectTo($auth.user, $ziggy)
        return redirect(target)
    }

}
