export default function ({ route, store, $auth }) {

    if($auth.loggedIn) {

        route.meta.matomo = {
            userId: ['setUserId', String($auth.user.id)],
        }

    }

/*    route.meta.matomo = {
        //documentTitle: ['setDocumentTitle', 'Some other title'],
        //userId: ['setUserId', store.state.userId],
        enableLinkTracking: ['enableLinkTracking'],
        trackAllContentImpressions: ['trackAllContentImpressions'],
        //asdf: ['sdfsdf'],
        //consentRequired: ['consentRequired'],
    }*/
}
